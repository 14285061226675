import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';



import App from 'components/App'
import Main from 'components/Pages/Main';


class ErrorComponent extends React.Component {
	render() {
		return (
			<div className="page-slider-slide page-active error">
				<h1>404</h1>
			</div>
		)
	}
}

ReactDOM.render(
	<BrowserRouter>
		<App>
	     	<Switch>
	    		<Route path="/" component={Main} />
	    		<Route path="*" component={ErrorComponent}/>
	    	</Switch>
    	</App>
	</BrowserRouter>, 
	document.getElementById('root')
);



