import axios from 'axios';
import qs from 'qs';

export async function retrieveGooglePhotosAlbumContents (albumId) {

	try {
		const googleApiAccessToken = await retrieveGoogleApiAccessToken();

		const options = {
			method: 'POST',
			headers: { 
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + googleApiAccessToken,
			},
		  	data: {
				"pageSize":"100",
				"albumId": albumId
			},
		  	url: 'https://photoslibrary.googleapis.com/v1/mediaItems:search'
		};
		
		const albumData = await axios(options);
		
		return albumData.data.mediaItems;
	
	} catch (e) {		
		throw new Error('Error: could not retrieve album contents ' + e);
	}
}

export async function retrieveGoogleApiAccessToken () {

	try {

		const options = {
			method: 'POST',
			url: 'https://www.googleapis.com/oauth2/v4/token',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			data: qs.stringify({
				client_id: "827063440599-6dimng5637mvjintja53j4d5j80drk08.apps.googleusercontent.com",
				client_secret: "0y_unvURVvz-96v9y5sSaE-T",
				refresh_token: "1/FN4TiduDH-rrdvSvWfUdrOPw-2tARVJwkdJpXXSdHew",
				grant_type: "refresh_token"
			})
		}

		const accessTokenData = await axios(options);

		return accessTokenData.data.access_token;

	} catch (e) {
		throw new Error('Error: could not retrieve google api access token ' + e);
	
	}
}