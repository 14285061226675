export var BlogDataSet = [
	{
		'DataType': 'blog',
		'BlogID': 'kauai-2018',
		'imageURL':'/media/blog/cover-photos/kauai-2017-cover-compressor.jpg',
		'styleType': 'tall',
		'title': "Living on the Garden Isle, Kauai, Hawaii",
		'type': 'trip',
		'showNavigation': false,
		'sections': [
			{
				'type':'main',
				'title': 'overview',
				'content': {
					'backgroundImageUrl': '/media/blog/cover-photos/kauai-2017-cover-compressor.jpg',
					'header': "21°39'53\"N 158°3'6\"W", 
					'subheader': 'Napali Coast, Kauai',
					'description': 'Living in the jungle!',
					'googlePhotoAlbumId': 'AOGXwH2qUZ5m02wam_5u0hzWbW9dMkl-0iZS2FQ0pM0X9F-yra9Giro03HhLTP4ZWzp69HlW9diV'
				}
			},
		],
	},
	{
		'DataType': 'blog',
		'BlogID': 'van-life-california',
		'imageURL':'/media/blog/cover-photos/california-2018-cover-compressor.JPG',
		'styleType': 'tall',
		'title': "Living the van life in California",
		'type': 'trip',
		'showNavigation': false,
		'sections': [
			{
				'type':'main',
				'title': 'overview',
				'content': {
					'backgroundImageUrl': '/media/blog/cover-photos/california-2018-cover-compressor.JPG',
					'header': "36°14'57’’N 121°48'55''W", 
					'subheader': 'California',
					'description': '6 months in a van',
					'googlePhotoAlbumId': 'AOGXwH0ZratjD1Ua6uLPkkP9yrrwprx-JfmIaLGqgKj-nF2_LaBRWkdPhGRD7KtatZ1_geILKmim'
				}
			},
		],
	},
	// {
	// 	'DataType': 'blog',
	// 	'BlogID': 'ram-dass-beyond-success',
	// 	'imageURL':'https://i.ytimg.com/vi/viNlErR88GE/maxresdefault.jpg',
	// 	'styleType': 'tall',
	// 	'title': "Ram Dass Beyond Success",
	// 	'link': "https://www.youtube.com/watch?v=Orb8cGSqS-A",
	// 	'type': 'video',
	// 	'showNavigation': false,
	// 	'sections': [
	// 		{
	// 			'type':'video',
	// 			'title': 'video',
	// 			'content': {
	// 				'videoOrigin': 'youtube',
	// 				'videoSource': 'https://www.youtube.com/watch?v=Orb8cGSqS-A'
	// 			}
	// 		},
	// 	],
	// },
	{
		'DataType': 'blog',
		'BlogID': 'costa-rica-adventures-2018',
		'imageURL':'/media/blog/cover-photos/costarica-2018-cover-compressor.jpg',
		'styleType': 'tall',
		'title': "Costa Rica Adventures",
		'type': 'trip',
		'showNavigation': false,
		'sections': [
			{
				'type':'main',
				'title': 'overview',
				'content': {
					'backgroundImageUrl': '/media/blog/cover-photos/costarica-2018-cover-compressor.jpg',
					// 'backgroundVideo': {
					// 	'origin': 'youtube',
					// 	'source': 'https://www.youtube.com/watch?v=RgvaeOMBBdE&playsinline=1'
					// },
					'header': "9°9'7\"N 83°44'34\"W", 
					'subheader': 'Uvita, Costa Rica',
					'description': 'Journey through da jungle',
					'googlePhotoAlbumId': 'AOGXwH0cuWT1o89b40QI04GKdBras44_UXg6kCCyHfybJwIu5Sel9eVE2Aw6mwO5NiR5J0gvxglQ',
					'story': `
						I’m lonely. I always get lonely. A lot of my life is filled with what I would define as loneliness. Its the same feeling again and again. This empty stomach feeling while I sit here in the middle of the street in Peru. Peru has this massive stretch of coast… its empty, foggy, and eery. Tall stretches of empty coastal cliffs spanning hundreds of miles. I sit here, in the emptiness, alone. Like i’m swimming out into a dark lake in the middle of the night. Its creepy. My body craves the comfort of a home or just something i’m used to. But once I get out there, my breath calms, and a bliss overtakes me as I let go.

						My entire life was this repetitive switch between having a home, and then being alone. Having a tribe, then moving on. Coming and going, coming and going. And often, I found myself back in the middle of that eery lake. With no one around. Just wading. And after so long of floating around, I kept being over taken over by that same bliss. I don’t know what it is. Christian’s would call it God. Hippies will say its the Universal energy or Pachi Mama. Hindu’s will say its Krishna. I don’t have a name for it at this point. I only know how it feels.

						After swimming out to that lake so many times, one thing became clear. I wasn’t alone. The water moved around me, making splashes as I floated in it. It reflected a picture of the the stars as they shined down towards me. The wind rushed through the empty sky. Crickets sat along the shore singing while some interesting creatures were probably going about their business below my feet. The loneliness started to dissipate, but fear took its place. I felt separate from this world around me, and so I was afraid of how it might treat me. So I closed my eyes and imagined an orchestra of instruments. Nature was playing a masterpiece for me. The fear soon began to fade, and I realized that I too was apart of this spectacular orchestra.

						The perspective of feeling separate from one another has caused us so much trouble. When a being enters into an environment and feels as though it is not of that environment, meaning it believes it is separate, it naturally feels the need to control the environment in order to be secure and safe. As I look around our world, I can see this happening left and right. We feel so alienated that we put up borders, walls, and barriers between one another.

						As humans, we have lost the fundamental reality that we are connected. Take the study of a plant as an example. A botanist could spend an entire life-time lecturing the complexity of a particular plant. He or she will describe each microscopic piece of the system and how each piece is promoting the overall growth of the plant down to the tiniest atomic molecule. Now imagine the endless amounts of systems on our planet that we could apply this logic too. Take for example, the process of the the solar orbital system(moons, galaxies, tides). Or the beautiful migration of whales or sea turtles. Well this is all fine and perfect until we are asked to take a look at ourselves to do a similar examination. And our response is “no i’m not apart of any system. I am an individual. I am not connected.” When a scientist has the microscope, and looks at a system from the outside in as a spectator, it is easy for them to see the system flowing within. On the other hand, if the scientist is in and of the system, it is much more difficult for them to see that process occurring as they are apart of it. This is the issue at its core. As humans, we don’t see this beautiful process happening because we are in the process itself.

						So how do we get back into touch with the true reality of nature? How do we merge back into the connectedness. Why is it that the turtles know how to migrate hundreds of miles when they are born? Why is it that the birds and the dogs know when its going to rain? They don’t have complex language, or math, or science. Its because these animals are intuitively involved with the nature around them. They are in the Tao. But as humans, we spend all of our time living inside a mental architecture in our heads. We spend practically zero time in the present moment watching this beautiful miracle unfold. When is the last time you’ve listened to your breath? This has kept you alive since you were a tiny baby infant has gone unnoticed for so long. 

						We all grew up in such a separated, divided, and judgemental space. Our constructs are deeply rooted in us. But we have the power to break down these structures and come back home. Deep inside us, we are yearning to be together again. So take the time to swim out into your lake, whatever that may be for you. Feel connected again. Come back to the moment. Come back to your breath. Do not feel threatened. There is nothing to be afraid of. Feel the grass under your feet, the wind in your hair, the water on your body. Come back home to the now. It is truly the only thing that is actually here. 

					`
				}
			},
		],
	},
	{
		'DataType': 'blog',
		'BlogID': 'nicaragua-adventures-2018',
		'imageURL':'/media/blog/cover-photos/nicargua-2018-cover-compressor.jpg',
		'styleType': 'tall',
		'title': "Nicaragua Adventures",
		'type': 'trip',
		'showNavigation': false,
		'sections': [
			{
				'type':'main',
				'title': 'overview',
				'content': {
					'backgroundImageUrl': '/media/blog/cover-photos/nicargua-2018-cover-compressor.jpg',
					'header': "11°24'24\"N 86°2'53\"W",
					'subheader': 'Playa Colorados, Nicaragua',
					'description': 'Rocks and surf and stuff',
					'googlePhotoAlbumId': 'AOGXwH0LKpGcRWRmD0Y8ooFc_G7BYCRN7nm09P7J_FOEFhRtrmMrojEnwt_WFTzIW-pDSB1nqwy0'
				}
			},
		],
	},
	// {
	// 	'DataType': 'blog',
	// 	'BlogID': 'alan-watts-explains-awakening',
	// 	'imageURL':'https://cdn-images-1.medium.com/max/1600/1*d9eFnjnyPC8UNteF3ZkXWw.jpeg',
	// 	'styleType': 'tall',
	// 	'title': "Alan Watts Explains what Awakening Means",
	// 	'link': "https://www.youtube.com/watch?v=7SfZZlpfaN0&t=2s",
	// 	'type': 'video',
	// 	'showNavigation': false,
	// 	'sections': [
	// 		{
	// 			'type':'video',
	// 			'title': 'video',
	// 			'content': {
	// 				'videoOrigin': 'youtube',
	// 				'videoSource': 'https://www.youtube.com/watch?v=7SfZZlpfaN0&t=2s'
	// 			}
	// 		},
	// 	],
	// },
	{
		'DataType': 'blog',
		'BlogID': 'elsalvador-adventures-2018',
		'imageURL':'/media/blog/cover-photos/elsalvador-2018-cover-compressor.jpg',
		'styleType': 'tall',
		'title': "El Salvador Adventures",
		'type': 'trip',
		'showNavigation': false,
		'sections': [
			{
				'type':'main',
				'title': 'overview',
				'content': {
					'backgroundImageUrl': '/media/blog/cover-photos/elsalvador-2018-cover-compressor.jpg',
					'header': "13°29'33\"N  89°22'53\"W", 
					'subheader': 'Playa Tunca, El Salvador',
					'description': "",
					'googlePhotoAlbumId': 'AOGXwH2EQg3E-g7NsWlfXWYLvlCgswa1F1AhesbA5l3VSvolioSXeNsi97mEYqcEkIeOd8EByLrd',
					'story': `
					It always happens really fast… Like it was only for a single frame. Like a click of a camera button, then its over. Every time. 
					I’m not sure what causes the “success” or “failure” of the whole process, or if theres even any distinguishing between the two. 
					I see this massive wall start to build up behind me. I swear its sort of like a big monster that just slowly approaches and all of a sudden its just right there on top of you. Unlucky(or lucky - not sure) for me, i’m in the perfect spot for this monster. Everyone and their mother is either sitting in the line-up or on the shore perched on the cobblestone wall. I feel the pressure and I know I can’t miss this one. 
					I half paddle, sort of hoping someone swoops behind me to snag it, because at this point my heart is following it’s basic human instinct and screaming at me to just turn around and get out of the way. The wall reaches a peak behind me and at this point i’m only looking forward. The rest is natural. Theres no thinking involved. Its just doing what i’ve always done. My hands plant themselves at the front of the board and my feet twist to dig into the wax. This one peaks up a bit so 
					I know theres going to be a decent air-drop before i can get to the bottom to turn. 
					My whole body is already positioned for the turn. But then it happens. Its like when you’re so sure of something, and then the next thing you know you’re completely upside down. Except this time I was literally upside down. My board had pitched straight into the few feet of water up under me and i’m heading straight for the rocks. Its hard to keep track of time under water. Its just dark and theres no way up or down. I do my best to keep my calm. The easiest way to drown is to panic so
					 I try and just count my way though it. 1….2….3….4…5…. and by about 6 or 7, my body is in full panic mode. My leash is still attached to my ankle and so i grab it and follow it all the way up like i’m climbing a rope. I finally reach my board and just hug it until it breaks the water’s surface. I see stars flashing in my vision and suck in as much breath as I can before the next wave slams on top of me. 
					 
					 As a human on this planet, i’m addicted to experiences that make my body and mind feel “high”. It comes down to the experiences and events that release neurological chemicals in my brain like dopamine, adrenaline, serotonin, and so on. Over time, i’ve realized the negative impact that these addictive sensual pleasures have had on my life and the energy around me. 
					 
					 • My addiction to money, almost destroyed one of the best friendships i’ve ever had.  
					 • My addiction to meaningless sexual experiences has definitely hindered really good relationships around me  
					 • My addiction to a partner kept me in a really bad relationship for a long time and also destroyed the friendship between us  
					 • and many many more…  

					 Anything can become an addiction. There are the obvious ones like sex, money, drugs. And its easy to see how people get themselves in to trouble when they are so focused on fulfilling that insatiable desire. But what about the not so obvious ones? Addicted to being organized. Addicted to traveling. Addicted to surfing. Those don’t seem like they could have a negative impact but they can. Some people are so overly organized and can’t stand a world without plans, dates, and schedules that it causes a ton of unneeded stress and anxiety. And probably the biggest one to mention here is the addiction to social media. Groups of engineers sit in rooms and their sole purpose is to design a system that ensures we receive a positive feedback loop of dopamine in our brains which keep us returning to their application. This is similar to watching what type of food you digest into your body. If your favorite restaurant was cooking up dogs in the back and putting it on your plate, you would want to know. Digital platforms are feeding your brain, and as a user, you have a right to know what is really being put in there.  This is what Instagram, Facebook, and other applications are doing to our brains. We open up the app and see beautiful colors and animations light up the screen. This inhibits dopamine to be released in our brains. And overtime this process creates a cycle where we constantly want to open up the app again and again so we can keep getting that same feeling. This addiction to a basic neurological chemical has pushed our generation to be so out of touch with the beautiful energy that surrounds all of us. That goes for any experience we have as humans. We have a right to know exactly how each experience is effecting our mind and body. And we have a right to make a conscious decision on whether we want to continue with that process.
					 
					 We all have this natural path that the universe has set us on. It is being in the Tao. And the addiction to these sensual pleasures can really throw us out of that flow. It is good to watch how the things we indulge in, impact our soul and also the souls around us. Watch how its transforming our environment and watch where our tendencies take us? If there is one thing I learned this trip, its the realization of the power behind a human’s addiction to a sensual pleasure. We ruin relationships for meaningless sexual pleasure. We throw raging tantrums over synthetic green paper. And destroy our bodily temple for substances that give you a few shots of dopamine to the brain. Even traveling can become an addiction! I’ve seen that in myself as well. Theres no self-judgement. Were on this planet to do what we do! But it is good for me to take time to 
					 self analyze and watch where those tendencies take me. Surfing had become such a habit and necessity for me that I had missed a lot of what some of these beautiful countries had to offer. Again, no self-judgement. It all just happens. But there are lessons to learn in each of these events and this was what I took away as I paddled out of the line up that day.
					 `
				}
			},
		],
	},
	{
		'DataType': 'blog',
		'BlogID': 'panama-adventures-2018',
		'imageURL':'/media/blog/cover-photos/panama-cover-compressor.jpg',
		'styleType': 'tall',
		'title': "Panama Adventures",
		'type': 'trip',
		'showNavigation': false,
		'sections': [
			{
				'type':'main',
				'title': 'overview',
				'content': {
					'backgroundImageUrl': '/media/blog/cover-photos/panama-cover-compressor.jpg',
					'header': "\"N \"W", 
					'subheader': 'Pacific Coast Panama',
					'description': "",
					'googlePhotoAlbumId': 'AOGXwH2m6tN8r96uyCBHrRq7njOjuCMgPkY80o9S4RES3Qgn4KNsPONSsJD20jVSKyN75Tr27cFW'
				}
			},
		],
	},
	// {
	// 	'DataType': 'blog',
	// 	'BlogID': 'ram-dass-being-free-together',
	// 	'imageURL':'https://cdn.ramdass.org/wp-content/uploads/2015/10/rdsmilecouch-730x400.jpg',
	// 	'styleType': 'tall',
	// 	'title': "Ram Dass on Being Free Together",
	// 	'link': "https://www.youtube.com/watch?v=Ir5ydNXpJlA&playsInline=1",
	// 	'type': 'video',
	// 	'showNavigation': false,
	// 	'sections': [
	// 		{
	// 			'type':'video',
	// 			'title': 'video',
	// 			'content': {
	// 				'videoOrigin': 'youtube',
	// 				'videoSource': 'https://www.youtube.com/watch?v=Ir5ydNXpJlA&playsInline=1'
	// 			}
	// 		},
	// 	],
	// },
	{
		'DataType': 'blog',
		'BlogID': 'belize-adventures-2018',
		'imageURL':'/media/blog/cover-photos/belize-cover-compressor.jpg',
		'styleType': 'tall',
		'title': "Belize Adventures",
		'type': 'trip',
		'showNavigation': false,
		'sections': [
			{
				'type':'main',
				'title': 'overview',
				'content': {
					'backgroundImageUrl': '/media/blog/cover-photos/belize-cover-compressor.jpg',
					'header': "\"N \"W", 
					'subheader': 'Kay Kaulker, Belize',
					'description': "Coral reefs, crystal water, and spooky caves",
					'googlePhotoAlbumId': 'AOGXwH0jYyQ4wDUEDif0U5hYAD1FMKyxxPUy8z6hx4q6UhZA0lTghJHtfIYW4ozdqVVjSIhPTI-N'
				}
			},
		],
	},
	// {
	// 	'DataType': 'blog',
	// 	'BlogID': 'ram-dass-nowhere-to-stand',
	// 	'imageURL':'https://i.ytimg.com/vi/CkUgExBDekE/hqdefault.jpg',
	// 	'styleType': 'tall',
	// 	'title': "Ram Dass Nowhere to Stand",
	// 	'link': "https://www.youtube.com/watch?v=CkUgExBDekE&playsInline=1",
	// 	'type': 'video',
	// 	'showNavigation': false,
	// 	'sections': [
	// 		{
	// 			'type':'video',
	// 			'title': 'video',
	// 			'content': {
	// 				'videoOrigin': 'youtube',
	// 				'videoSource': 'https://www.youtube.com/watch?v=CkUgExBDekE&playsInline=1'
	// 			}
	// 		},
	// 	],
	// },
	{
		'DataType': 'blog',
		'BlogID': 'northshore-oahu-2017',
		'imageURL':'/media/blog/oahu-2017/Danger-Sign+-min.jpg',
		'styleType': 'tall',
		'title': "Living at Sunset Beach, Hawaii",
		'type': 'trip',
		'showNavigation': false,
		'sections': [
			{
				'type':'main',
				'title': 'overview',
				'content': {
					'backgroundImageUrl': '/media/blog/oahu-2017/pipeline-min.jpg',
					'header': "21°39'53\"N 158°3'6\"W", 
					'subheader': 'Pipelina, Hawaii',
					'description': 'The Seven Mile Miracle',
					'googlePhotoAlbumId': 'AOGXwH3gpW_IwYgPF4dNP-eTplClJD3URF5GER5htyE-46hWSHp63VKakBOXQt7p3Z_-cmu0FLg8'
				}
			},
		],
	},
	// {
	// 	'DataType': 'blog',
	// 	'BlogID': 'ram-dass-advanced-course',
	// 	'imageURL':'https://www.ramdass.org/wp-content/uploads/2017/11/ramdassiconic-730x400.jpg',
	// 	'styleType': 'tall',
	// 	'title': "Ram Dass Advanced Course",
	// 	'link': "https://www.youtube.com/watch?v=bJ0lA8KtQhk",
	// 	'type': 'video',
	// 	'showNavigation': false,
	// 	'sections': [
	// 		{
	// 			'type':'video',
	// 			'title': 'video',
	// 			'content': {
	// 				'videoOrigin': 'youtube',
	// 				'videoSource': 'https://www.youtube.com/watch?v=bJ0lA8KtQhk'
	// 			}
	// 		},
	// 	],
	// },
	{
		'DataType': 'blog',
		'BlogID': 'guatemala-2018',
		'imageURL':'/media/blog/guatemala-2018/guatemala-cover-min.jpg',
		'styleType': 'tall',
		'title': "Guatemala Adventures",
		'type': 'trip',
		'showNavigation': false,
		'sections': [
			{
				'type':'main',
				'title': 'overview',
				'content': {
					'backgroundImageUrl': '/media/blog/guatemala-2018/guatemala-cover-min.jpg',
					'header': "21°39'53\"N 158°3'6\"W", 
					'subheader': 'Mayan Ruins, Guatemala',
					'description': '',
					'googlePhotoAlbumId': 'AOGXwH01_fD0RdeX7tHerIHz18OHWRl-qL5B43DhIQVWX2IN2q1LlZoIbIqKBsCtnx2Y_Yob9u41'
				}
			},
		],
	},
	// {
	// 	'DataType': 'blog',
	// 	'BlogID': 'terrance-mckenna-food-of-the-gods',
	// 	'imageURL':'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Hanna_jon_1999_mckenna_terence.jpg/220px-Hanna_jon_1999_mckenna_terence.jpg',
	// 	'styleType': 'tall',
	// 	'title': "Terrance Mckenna on Food of the Gods",
	// 	'link': "https://www.youtube.com/watch?v=V5T5xNmAI4M",
	// 	'type': 'video',
	// 	'showNavigation': false,
	// 	'sections': [
	// 		{
	// 			'type':'video',
	// 			'title': 'video',
	// 			'content': {
	// 				'videoOrigin': 'youtube',
	// 				'videoSource': 'https://www.youtube.com/watch?v=V5T5xNmAI4M'
	// 			}
	// 		},
	// 	],
	// },	
	// {
	// 	'DataType': 'blog',
	// 	'BlogID': 'terrance-mckenna-ufo-outsider',
	// 	'imageURL':'https://pbs.twimg.com/profile_images/913116822249070593/Mt3oakgM_400x400.jpg',
	// 	'styleType': 'tall',
	// 	'title': "UFO Outsider Terrance Mckenna",
	// 	'link': "https://www.youtube.com/watch?v=crVV7mFBfBg",
	// 	'type': 'video',
	// 	'showNavigation': false,
	// 	'sections': [
	// 		{
	// 			'type':'video',
	// 			'title': 'video',
	// 			'content': {
	// 				'videoOrigin': 'youtube',
	// 				'videoSource': 'https://www.youtube.com/watch?v=crVV7mFBfBg',
	// 				'showControls': false
	// 			}
	// 		},
	// 	],
	// },
]


