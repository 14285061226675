import React from 'react'
import MainButton from 'components/Widgets/MainButton';

export default class SocialWidget extends React.PureComponent {
	constructor(props){
		super(props)
		this.state = {
			socialMenuExpanded: false
		}
		this.animateSocialMenu = this.animateSocialMenu.bind(this)
	}

	componentDidMount () {
		
	}

	shouldComponentUpdate(nextProps, nextState) {
		if(this.state.socialMenuExpanded !== nextState.socialMenuExpanded) {
			return true;
		}
		return false;
	}
	
	animateSocialMenu () {

		if (this.state.socialMenuExpanded) {
			this.setState({socialMenuExpanded: false})
		} else {
			this.setState({socialMenuExpanded: true})
		}
		
	}

	openSocial (platform) {
		switch (platform) {
			case 'instagram':
				window.open('https://www.instagram.com/dailenspencer/','_blank');
				break;
			case 'facebook':
				window.open('https://facebook.com/dailenspencer', '_blank');
				break;
			case 'youtube':
				window.open('https://www.youtube.com/channel/UCVWK-7FdJIPznnSojzqi22A', '_blank')
				break;
			case 'snapchat':
				window.open('https://snapchat.com/add/dailenspencer', '_blank');
				break;
			case 'twitch':
				window.open('https://twitch.tv/hellodailen', '_blank');
				break;
			case 'linkedin':
				window.open('https://www.linkedin.com/in/dailen-spencer-247277110', '_blank')
				break;
			case 'github':
				window.open('https://github.com/dailenspencer', '_blank');
				break;
			default:
				break;
		}
	}
	
	render() {

		var socialHeight = this.state.socialMenuExpanded ? '140px' : '0px';
		var socialItemClassName = this.state.socialMenuExpanded ? 'active' : 'inactive';
		var mainButtonDirection = this.state.socialMenuExpanded ? 'pointUp' : 'pointDown';


		return (
			<div className='social-widget'>
				<MainButton type={'navigation'} position={'topRight'} direction={mainButtonDirection} clickHandler={this.animateSocialMenu}/>
				<div className='social' style={{'height':socialHeight}}>
					<img className={socialItemClassName} src="/media/social/instagram.svg"  alt="instagram logo" onClick={() => this.openSocial('instagram') }/>
		    		<img className={socialItemClassName} src="/media/social/facebook.svg"  alt="facebook logo" onClick={ () => this.openSocial('facebook') }/>
		    		<img className={socialItemClassName} src="/media/social/youtube.svg"  alt="youtube logo" onClick={ () => 
		    			this.openSocial('youtube') }/>
		    		<img className={socialItemClassName} src="/media/social/snapchat.png"  alt="snapchat logo" onClick={ () => this.openSocial('snapchat') }/>
		    		<img className={socialItemClassName} src="/media/social/twitch.png"  alt="twitch logo" onClick={ () => 
		    			this.openSocial('twitch') }/>
		    		<img className={socialItemClassName} src="/media/social/linkedin.png"  alt="linkedin logo" onClick={ () => this.openSocial('linkedin') }/>
		    		<img className={socialItemClassName} src="/media/social/github.png"  alt="github logo" onClick={ () => this.openSocial('github') }/>
				</div>
			</div>
		);
	}
}