const $ = window.$;
export let NavigationButtonHelper = {
	setColor: function (style) {
		if (!style) {
			$('.navigation-button').css({'background-color':'transparent'})
			$('.navigation-button div').css({'background-color': 'white'})
			$('.back-button div').css({'background-color': 'white'})
			return;
		}
		$('.navigation-button').css({'background-color':style.primaryColor})
		$('.navigation-button div').css({'background-color': style.secondaryColor})
		
		//Color back button
		$('.back-button').css({'background-color': style.primaryColor})
		$('.back-button div').css({'background-color': 'white'})
	},
	showBackButton: function () {
		$('.back-button').animate({
			'opacity':'1.0',
			'left':'90px'
		}, function () {
			$('.back-button div:nth-child(1)').css({
				'transform':'translate3d(14px, 17px, 0px) rotate(-45deg)', 
			})
			$('.back-button div:nth-child(2)').css({
				'transform':'translate3d(14px, 27px, 0px) rotate(45deg)',
			})
		})
		
	},
	collapseBackButton: function () {
		$('.back-button').animate({
			'opacity':'0.0',
			'left':'0px'
		}, function () {
			$('.back-button div:nth-child(1)').css({
				'transform': 'translate3d(13px, 22px, 0px)',
			})
			$('.back-button div:nth-child(2)').css({
				'transform':'translate3d(13px, 22px, 0px)', 
			})
		})

		
	}
}

