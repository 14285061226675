import Shuffle from 'shufflejs';
import React from 'react'
import {deviceIsMobile} from 'lib/Helpers/JqueryHelpers';


class ShuffleWidget extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			
		}
		
	}


	componentDidMount () {
		var element = document.querySelector('.shuffle-container');
		var shuffleInstance = new Shuffle(element, {
		  itemSelector: '.picture-item',
		  sizer: '.sizer'
		});
	}
	
	buildPictureItems () {
		return this.props.items.map((item, index) => {
			return (
				<PictureItem item={item} clickHandler={this.props.clickHandler} key={index}/>
			)
		})
	}

	render() {
		return (
			 <div className="shuffle-container">
			 	{this.buildPictureItems()}
				<div className="sizer"></div>
			</div>
			 
		);
	}
}
export default ShuffleWidget;




var itemTypeImageSources = {
	'link': '/media/Icons/unlink.png',
	'video': '/media/Icons/video-camera.png',
	'trip': '/media/Icons/map-pin.png'
}
class PictureItem extends React.Component {
	
	handleClick () {
		window.open(this.props.item.link,'_blank');
	}

	render () {

		var backgroundImageStyle = {
			'backgroundImage': 'url(' + this.props.item.imageURL + ')',
			'transform': deviceIsMobile() ? 'scale(1.0)' : ''
		}

		
		var itemTypeImageSource;
		switch(this.props.item.type){
			case 'link':
				itemTypeImageSource = itemTypeImageSources.link
				break;
			case 'video':
				itemTypeImageSource = itemTypeImageSources.video
				break
			case 'trip':
				itemTypeImageSource = itemTypeImageSources.trip
				break;
			default:
				itemTypeImageSource = itemTypeImageSources.link
				break;
		}

		return (
			<div className={'picture-item ' + this.props.item.styleType} onClick={() => this.props.clickHandler(this.props.item)}>
		 		<div className="aspect aspect--16x9">
			    	<div className='picture-item-bg' style={backgroundImageStyle}>
			    		<div className='overlay'>
				    		<h1>{this.props.item.title}</h1>
				    		<img className='type-icon' src={itemTypeImageSource}/>
				    	</div>
			    	</div>			    	
			    </div>
		    </div>
		)
	}
}





