import React from 'react'
import BlockArrowWidget from 'components/Widgets/Buttons/BlockArrow.widget';
import Form from 'components/Widgets/Form';
import ParallaxBackground from '../../Widgets/ParallaxBackground';
import { withRouter} from 'react-router-dom';

const $ = window.$;
// injectTapEventPlugin();

const parallaxConfiguration = {
	'backgroundImageUrl': 'media/photography/peru-wavepeel-compressor.jpeg',
	'overlayItemImageUrls': []
}

class SubscribePage extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			
		}
		
	}

	componentWillMount () {

	}

	slideHome () {
		this.props.history.push({
		  pathname: '/home',
		  originPath: '/subscribe',
		  targetPath: '/home'
		})
	}


	render() {
		return (
			<div className='page-subscribe'>
				<ParallaxBackground parallaxConfiguration={parallaxConfiguration}/>
				<div className='overlay'>
					<div className='arrow-holder'>
						<BlockArrowWidget clickHandler={() => this.slideHome()}/>
					</div>
					<EmailForm textFieldStyle={textFieldStyle} color={"#ffffff"}/>
				</div>
			</div>
		);
	}
}

export default withRouter(SubscribePage);


const EmailForm = Form('email');
const textFieldStyle = {
	nameField: {
		width: $(window).width() < 544 ? '100%' : '272px'
	},
	emailField: {
		width: $(window).width() < 544 ? '100%' : '272px'
	},
	messageField: {
		display: 'none',
		width: $(window).width() < 544 ? '100%' : '272px'
	}
}