
import React from 'react'
const $ = window.$;

export default class MainButton extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			
		}
		
	}

	shouldComponentUpdate(nextProps, nextState) {
		return true;
	}

	componentDidMount () {
		
	}

	clickHandler (event) {
		
		//Use parent element to grab arrow children
		var mainButton = $(event.target).get(0).parentElement;
		var arrowOne = $(mainButton).find('.arrow-1');
		var arrowTwo = $(mainButton).find('.arrow-2');

		//Animate arrows based on direction
		if (this.props.direction === 'pointRight') {

			$(arrowOne).addClass('rotate')
			$(arrowTwo).addClass('rotate')

		} else if (this.props.direction === 'pointLeft') {
			$(arrowOne).addClass('rotate')
			$(arrowTwo).addClass('rotate')
		}

		//Remove classes to reset animation
		setTimeout(() => {
			$(arrowOne).removeClass('rotate')
			$(arrowTwo).removeClass('rotate')
		},500)


		if (this.props.clickHandler !== null) this.props.clickHandler(event);
	}
	
	render() {
		var propStyle = {
			'backgroundColor': this.props.backgroundColor
		}
		//Video will alwys have large and mid wrap
		if (this.props.type == 'video') {
			return (
				<div className='main-button-wrapper video-button'>
					<div className='main-button-large-wrap' style={propStyle}></div>
					<div className='main-button-mid-wrap' style={propStyle}></div>
					<div className={'main-button ' + this.props.direction}>
			  			<div className='main-button-circle' style={propStyle} onClick={(e) => this.clickHandler(e)}></div>
						<div className='main-button-arrows'>
							<div className='arrow-1' style={{'backgroundColor':this.props.arrowColor}}></div>
							<div className='arrow-2' style={{'backgroundColor':this.props.arrowColor}}></div>
						</div>
					</div>
				</div>
			)
		}
		return (
			  <div className={'main-button-wrapper'}>
			  	 <div className={'main-button ' + this.props.direction}>
			  		
					<div className='main-button-circle' style={propStyle} onClick={(e) => this.clickHandler(e)}></div>
					<div className='main-button-arrows'>
						<div className='arrow-1' style={{'backgroundColor':this.props.arrowColor}}></div>
						<div className='arrow-2' style={{'backgroundColor':this.props.arrowColor}}></div>
					</div>
				</div>
			  </div>
			 
		);
	}
}