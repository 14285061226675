const $ = window.$;
const TweenMax = window.TweenMax;
const CustomEase = window.CustomEase;

var slideInFromTop = {
	prepareForAnimation: function (componentSelector, windowHeight) {
		TweenMax.set($(componentSelector), {
			transform: 'translateY(' + windowHeight + 'px)'
		})
	},
	performAnimation: function (componentSelector, windowHeight) {
		TweenMax.fromTo($(componentSelector), 0.8,
			{
				transform: 'translateY(-' + windowHeight + 'px)'
	  		},
			{
				transform: 'translateY(0px)',
				ease: CustomEase.create("custom", "M0,0,C0.173,0,0.242,0.036,0.322,0.13,0.401,0.223,0.449,0.367,0.502,0.506,0.546,0.622,0.62,0.824,0.726,0.916,0.799,0.98,0.869,1,1,1"),
				onComplete: function () {
					setTimeout(() => {
						$(componentSelector).removeClass('transitioning');
						$(componentSelector).css({
							'z-index':'9',
						})
					},300)
				}
			}
		);
	}
}



var slideInFromBottom = {
	prepareForAnimation: function (componentSelector, windowHeight) {
		TweenMax.set($(componentSelector), {
			transform: 'translateY(-' + windowHeight + 'px)'
		})
	},
	performAnimation: function (componentSelector, windowHeight) {
		TweenMax.fromTo($(componentSelector), 0.8,
			{
				transform: 'translateY(' + windowHeight + 'px)'
	  		},
			{
				transform: 'translateY(0px)',
				ease: CustomEase.create("custom", "M0,0,C0.173,0,0.242,0.036,0.322,0.13,0.401,0.223,0.449,0.367,0.502,0.506,0.546,0.622,0.62,0.824,0.726,0.916,0.799,0.98,0.869,1,1,1"),
				onComplete: function () {
					setTimeout(() => {
						$(componentSelector).removeClass('transitioning');
						$(componentSelector).css({
							'z-index':'9',
						})
					},300)
				}
			}
		);
	}
}


var slideInFromLeft = {
	prepareForAnimation: function (componentSelector, windowWidth) {
		TweenMax.set($(componentSelector), {
			transform: 'translateX(-' + windowWidth + 'px)'
		})
	},
	performAnimation: function (componentSelector, windowWidth) {
		TweenMax.fromTo($(componentSelector), 0.8,
			{
				transform: 'translateX(-' + windowWidth + 'px)'
	  		},
			{
				transform: 'translateX(0px)',
				ease: CustomEase.create("custom", "M0,0,C0.173,0,0.242,0.036,0.322,0.13,0.401,0.223,0.449,0.367,0.502,0.506,0.546,0.622,0.62,0.824,0.726,0.916,0.799,0.98,0.869,1,1,1"),
				onComplete: function () {
					setTimeout(() => {
						$(componentSelector).removeClass('transitioning');
						$(componentSelector).css({
							'z-index':'9',
						})
					},300)
				}
			}
		);
	}
}


var slideInFromRight = {
	prepareForAnimation: function (componentSelector, windowWidth) {
		TweenMax.set($(componentSelector), {
			transform: 'translateX(' + windowWidth + 'px)'
		})
	},
	performAnimation: function (componentSelector, windowWidth) {
		TweenMax.fromTo($(componentSelector), 0.8,
			{
				transform: 'translateX(' + windowWidth + 'px)'
	  		},
			{
				transform: 'translateX(0px)',
				ease: CustomEase.create("custom", "M0,0,C0.173,0,0.242,0.036,0.322,0.13,0.401,0.223,0.449,0.367,0.502,0.506,0.546,0.622,0.62,0.824,0.726,0.916,0.799,0.98,0.869,1,1,1"),
				onComplete: function () {
					setTimeout(() => {
						$(componentSelector).removeClass('transitioning');
						$(componentSelector).css({
							'z-index':'9',
						})
					},300)
				}
			}
		);
	}
}



export const Animator = {
	slideInFromTop: slideInFromTop,
	slideInFromBottom: slideInFromBottom,
	slideInFromLeft: slideInFromLeft,
	slideInFromRight: slideInFromRight
} 
