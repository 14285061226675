import React from 'react'

class BlockArrowWidget extends React.Component {
	constructor(props){
		super(props)
	}

	handleClick () {
		if (this.props.clickHandler) {
			this.props.clickHandler();
		}
	}
	render() {
		
		return (
			<div className={'blockarrow-widget'} onClick={() => this.handleClick()}>
				<svg viewBox="0 0 32.634 32.634"  >
				<g>
					<path d="M16.317,32.634c-0.276,0-0.5-0.224-0.5-0.5V0.5c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v31.634   C16.817,32.41,16.594,32.634,16.317,32.634z" fill="#FFFFFF"/>
					<path d="M28.852,13.536c-0.128,0-0.256-0.049-0.354-0.146L16.319,1.207L4.135,13.39c-0.195,0.195-0.512,0.195-0.707,0   s-0.195-0.512,0-0.707L15.966,0.146C16.059,0.053,16.186,0,16.319,0l0,0c0.133,0,0.26,0.053,0.354,0.146l12.533,12.536   c0.195,0.195,0.195,0.512,0,0.707C29.108,13.487,28.98,13.536,28.852,13.536z" fill="#FFFFFF"/>
				</g>
				</svg>
			</div>
		)
	}
}

export default BlockArrowWidget;