import React from 'react';
import MainButton from 'components/Widgets/MainButton';
import VideoWidget from 'components/Widgets/VideoWidget';

const $ = window.$;

const videoPlayerOptions = {
    clickToPlay: false,
    controls: ['play-large', 'play', 'progress', 'fullscreen'],
    fullscreen: { enabled: true, fallback: true, iosNative: true }
}

export default class BlogVideoSection extends React.Component {

    constructor(props){
		super(props)
		this.state = {
			videoIsPlaying: false
		}
		
    }
    
    componentDidMount () {
        
    }

    handleVideoClick (videoPlayer) {
       videoPlayer.togglePlay();	
    }
    
	render() {

        var videoPlayerData = {
            'src': this.props.content.videoSource,
            'origin': this.props.content.videoOrigin
        }        

        return (
            <VideoWidget elementId={'player'} videoPlayerData={videoPlayerData} videoPlayerOptions={videoPlayerOptions} onClick={(videoPlayer) => this.handleVideoClick(videoPlayer)}/>
        )
    }
}