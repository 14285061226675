import React from 'react'
import randomColor from 'randomcolor';

import MoveIt from 'lib/MoveIt/MoveIt';

import Form from 'components/Widgets/Form';

import {NavigationButtonHelper} from 'lib/Helpers/NavigationButton';
const $ = window.$;

class AboutPage extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			windowWidth: $(window).width()
		}
		
	}

	/******Life Cycle Functions********/
	componentDidMount() {
		MoveIt().initialize();

		$(window).on('resize',() => {
			this.setState({windowWidth: $(window).width()})
		})

		if (this.props.scrollTo) {
			 $('.page-about').animate({
			 	scrollTop: $(".section-contact").offset().top + 500
			 },100)
		}

		NavigationButtonHelper.setColor({primaryColor:'rgba(119,210,119,0.7)'});
		
	}
	componentWillUnmount() {
        // you need to unbind the same listener that was binded.
        window.removeEventListener('scroll', this.onScroll, false);
        $(window).on('resize',false)
	}
	/**********************************/

	/******Build Functions********/
	buildAnimatedHeader(headerText) {
		return headerText.split('').map((char,index) => {
			var randomScrollSpeed = Math.floor(Math.random() * 10);
			var randomBackgroundColor = randomColor({hue: 'random',luminosity: 'dark',format: 'rgba'});

			
			if (this.state.windowWidth < 680 && char === ' ') {
				return (
					<span key={index} style={{'display':'block','clear':'both','marginBottom':'30px'}}/>
				)
			} else if (this.state.windowWidth > 680 && char === ' ') {
				return '';
			} else {
				return (
					<div className='box' data-scroll-speed={randomScrollSpeed} style={{'backgroundColor':randomBackgroundColor}}key={index}>{char}</div>
				)
			}
			
		})
	}
	/**********************************/

	/******Toggle Functions********/
	handleLearnMoreClick () {
		//todo use gsap here
		var offset = $(".section-whoweare").offset().top;
		$('.page-about').animate({
		 	scrollTop: offset
		 },800)
	}
	/**********************************/

	render() {
		//todo build header here
		var header = this.buildAnimatedHeader('NICE TO MEET YOU')
		
		return (
			<div className="page-about">
				<div className="header-wrapper">
				  	<div className='header'>
				  		{header}
					</div>
					<div className='learnmore' onClick={() => this.handleLearnMoreClick()}data-scroll-speed="1">Learn More</div>
				</div>
				<div className='section-whoweare' style={{'marginBottom':'100px'}}>
					<h1>Who Am I?</h1>
					<p1>
					Dailen Spencer is a remote software architect and entrepreneur. At a young age, a passion for technology, design, and software development grew over him. The open environment during his youth allowed his creativity to flourish. He attended the University of Florida as a Computer Science Major. Quickly outpacing the curriculum, Dailen left university and became the CEO & Founder of GreekLeaf, LLC. Since then, he has built a wide range of applications for businesses across the globe and pushed code to programs with millions of users. He has worked extensively, in front end design, database architecture, virtual reality development, machine learning, and much more. Scroll down to view some of the technical areas he has worked in. You can also view a few of his recent projects in the Projects tab. If you have a project that requires development or consultation, please feel free to contact him by email - dailenspencer@gmail.com. Dailen is an advocate for open-sourced education and autonomous learning. He hopes to inspire others to chase the unbeaten path and follow their dreams.   
					</p1>
				</div>
				<div className='section-whatido'>
					<h1>What I Do</h1>
					<div className='package-wrapper'>
						<div  style={{'marginTop':'50px', 'marginBottom':'50px'}}>
							<img src="media/skillsets/Javascript.png" alt="javascript logo"/>
							<p1>Javascript</p1>
						</div>
						<div style={{'marginTop':'50px', 'marginBottom':'50px'}}>
							<img src="media/skillsets/nodejs.png" alt="node logo"/>
							<p1>Node.js</p1>
						</div>
						<div  style={{'marginTop':'50px', 'marginBottom':'50px'}}>
							<img src="media/skillsets/react.png" alt="react logo"/>
							<p1>React</p1>
						</div>
						<div  style={{'marginTop':'50px', 'marginBottom':'50px'}}>
							<img src="media/skillsets/angular.png" alt="angular logo"/>
							<p1>Angular</p1>
						</div>
						<div style={{'marginTop':'50px', 'marginBottom':'50px'}}>
							<img src="media/skillsets/HTML5.png" alt="html logo"/>
							<p1>HTML5</p1>
						</div>
						<div style={{'marginTop':'50px', 'marginBottom':'50px'}}>
							<img src="media/skillsets/css3.png" alt="css logo"/>
							<p1>CSS3</p1>
						</div>
						<div  style={{'marginTop':'50px', 'marginBottom':'50px'}}>
							<img src="media/skillsets/jquery.png" alt="jquery logo"/>
							<p1>JQuery</p1>
						</div>
						<div style={{'marginTop':'50px', 'marginBottom':'50px'}}>
							<img src="media/skillsets/Swift.png" alt="swift logo"/>
							<p1>Swift</p1>
						</div>
						<div style={{'marginTop':'50px', 'marginBottom':'50px'}}>
							<img src="media/skillsets/xcode.png" alt="xcode logo"/>
							<p1>Xcode</p1>
						</div>
						<div style={{'marginTop':'50px', 'marginBottom':'50px'}}>
							<img src="media/skillsets/objective-c-compressor.png" alt="objective-c logo"/>
							<p1>Objective-C</p1>
						</div>
						<div style={{'marginTop':'50px', 'marginBottom':'50px'}}>
							<img src="media/skillsets/bash.png" alt="bash logo"/>
							<p1>Bash Scripting</p1>
						</div>
						<div  style={{'marginTop':'50px', 'marginBottom':'50px'}}>
							<img src="media/skillsets/python.png" alt="python logo"/>
							<p1>Python</p1>
						</div>
						<div  style={{'marginTop':'50px', 'marginBottom':'50px'}}>
							<img src="media/skillsets/unity-compressor.png" alt="unity logo"/>
							<p1>Unity</p1>
						</div>
						<div  style={{'marginTop':'50px', 'marginBottom':'50px'}}>
							<img src="media/skillsets/cplusplus-compressor.png" alt="cplusplus logo"/>
							<p1>C++</p1>
						</div>
						<div  style={{'marginTop':'50px', 'marginBottom':'50px'}}>
							<img src="media/skillsets/shopify-compressor.png" alt="shopify logo"/>
							<p1>Shopify & .liquid</p1>
						</div>
						<div style={{'marginTop':'50px', 'marginBottom':'50px'}}>
							<img src="media/skillsets/lambda-compressor.png" alt="lambda logo"/>
							<p1>AWS Lambda</p1>
						</div>
						<div style={{'marginTop':'50px', 'marginBottom':'50px'}}>
							<img src="media/skillsets/cloudfront-compressor.png" alt="cloudfront logo"/>
							<p1>AWS CloudFront</p1>
						</div>
						<div style={{'marginTop':'50px', 'marginBottom':'50px'}}>
							<img src="media/skillsets/s3.png" alt="s3 logo"/>
							<p1>AWS S3</p1>
						</div>
						<div style={{'marginTop':'50px', 'marginBottom':'50px'}}>
							<img src="media/skillsets/ec2.png" alt="ec2 logo"/>
							<p1>AWS EC2</p1>
						</div>
						<div style={{'marginTop':'50px', 'marginBottom':'50px'}}>
							<img src="media/skillsets/dynamodb-compressor.png" alt="dynamodb logo"/>
							<p1>AWS DynamoDB</p1>
						</div>
						<div style={{'marginTop':'50px', 'marginBottom':'50px'}}>
							<img src="media/skillsets/cognito.png" alt="cognito logo"/>
							<p1>AWS Cognito</p1>
						</div>
						<div style={{'marginTop':'50px', 'marginBottom':'50px'}}>
							<img src="media/skillsets/route53-compressor.png" alt="route 53 logo"/>
							<p1>AWS Route 53</p1>
						</div>
						<div style={{'marginTop':'50px', 'marginBottom':'50px'}}>
							<img src="media/skillsets/mql4-compressor.png" alt="mql4 logo"/>
							<p1>MQL4</p1>
						</div>
						<div style={{'marginTop':'50px', 'marginBottom':'50px'}}>
							<img src="media/skillsets/php-compressor.png" alt="php logo"/>
							<p1>PHP</p1>
						</div>
						<div style={{'marginTop':'50px', 'marginBottom':'50px'}}>
							<img src="media/aboutpage/tags.svg" alt="seo logo"/>
							<p1>Search Engine Optimization</p1>
						</div>
						<div style={{'marginTop':'50px', 'marginBottom':'50px'}}>
							<img src="media/skillsets/photoshop.png" alt="photoshop logo"/>
							<p1>Adobe Photoshop</p1>
						</div>
						<div style={{'marginTop':'50px', 'marginBottom':'50px'}}>
							<img src="media/skillsets/illustrator.png" alt="illustrator logo"/>
							<p1>Adobe Illustrator</p1>
						</div>
						<div style={{'marginTop':'50px', 'marginBottom':'50px'}}>
							<img src="media/skillsets/facebook.png" alt="facebook logo"/>
							<p1>Facebook Advertising</p1>
						</div>
						<div style={{'marginTop':'50px', 'marginBottom':'50px'}}>
							<img src="media/skillsets/instagram.png" alt="instagram logo"/>
							<p1>Instagram Advertising</p1>
						</div>
						<div style={{'marginTop':'50px', 'marginBottom':'50px'}}>
							<img src="media/skillsets/snapchat.png" alt="snapchat logo"/>
							<p1>Snapchat Advertising</p1>
						</div>
					</div>
					
				</div>
				{/* <div className='section-teammessage'>
					<h1>A Message From The Team</h1>
					<p1>Our team has developed an incredibly adaptable pace to parallel the exponential advancement in technology. We stay on the cutting edge of the software platforms and tools that we utilize on a daily basis. We have a depth of knowledge in a wide range of areas. We love to talk ideas and discuss the future. So whether you have thoughts to dabble in drop shipping, sell your art online, or even become a self-made engineer, talk to us and we can help(don’t worry - we don’t charge a thing)</p1>
				</div> */}
				<div className='section-contact'>
					<h1>Get In Touch</h1>
					<MessageForm textFieldStyle={textFieldStyle} color={"#B9BFBB"}/>
				</div>
			</div>
		);
	}
}
// AboutPage.contextTypes = {
//   router: React.PropTypes.object.isRequired
// };
export default AboutPage





const MessageForm = Form('message');
const textFieldStyle = {
	nameField: {
		width: $(window).width() < 544 ? '100%' : '272px'
	},
	emailField: {
		width: $(window).width() < 544 ? '100%' : '272px'
	},
	messageField: {
		width: $(window).width() < 544 ? '100%' : '272px'
	}
}