var loadedImagesUrls = []

export let ImageLoader = {
	//TODO: fix
	preloadImages: function (images) {
		return new Promise(function(resolve,reject){
			var imageLoadCount = 0;

			if (typeof images === 'object') {
				images = Object.keys(images).map(function(key){
					return images[key];
				})
			}

			if (Array.isArray(images)) {

				//TODO: add check to see if *all* images have already been preloaded, then filter out the ones that havent
				//TODO: fix binding of this so we push appropriate imageUrl to loadedImagesUrls array
				images.forEach((imageUrl) => {

			  		var img = new Image();
					img.onload = function() {
						loadedImagesUrls.push(imageUrl);
				    	checkImageCount();

					}
					img.src = imageUrl;
					if (img.complete) img.onload.bind(this, imageUrl);
			    })
			} else {
				reject('incorrect argument type sent to preloadImages function! Data type should be an array or object ');
			}
			

			function checkImageCount () {
				imageLoadCount++;
			    if (imageLoadCount === images.length) {
			    	resolve(false);
			  	}
			}
		})
	},
	preloadImage: function (imageUrl) {
		return new Promise(function(resolve,reject){
			
			//If image has already been loaded previously, resolve
			if (~loadedImagesUrls.indexOf(imageUrl)) {
				resolve(true);
				return;
			}

			var img = new Image();
			img.onload = function() {
				loadedImagesUrls.push(imageUrl);
		    	resolve(false);

			}
			img.src = imageUrl;
			if (img.complete) img.onload();

		})
	}
}