const $ = window.$;
const TweenMax = window.TweenMax;
const CustomEase = window.CustomEase;

var slideInformationFooterOutToLeft = {
    performAnimation: () => {
		TweenMax.to($('.overview .information-footer'), 0.5,
            {
                left: '-400px'
            }
        );
	}
}
var slideInformationFooterInFromLeft = {
    performAnimation: () => {
		TweenMax.to($('.overview .information-footer'), 0.5,
            {
                left: '40px',
            }
        );
	}
}

var slideMediaItemInFromLeft = {
	prepareForAnimation: function ($elem) {
		TweenMax.set($elem, {
            'margin-left':'-500px',
            onComplete: () => {
                $elem.addClass('off-screen');
            }
		})
	},
	performAnimation: function ($elem) {
		TweenMax.to($elem, 0.5,
			{
                'margin-left': '0px',
                 onComplete: () => {
                     $elem.addClass('on-screen');
                 }
	  		}
		);
	}
}

var slideMediaItemInFromRight = {
	prepareForAnimation: function ($elem) {
		TweenMax.set($elem, {
            'margin-right':'-500px',
            onComplete: () => {
                $elem.addClass('off-screen');
            }
		})
	},
	performAnimation: function ($elem) {
		TweenMax.to($elem, 0.5,
			{
                'margin-right': '0px',
                 onComplete: () => {
                     $elem.addClass('on-screen');
                 }
	  		}
		);
    }
}



export const Animator = {
    slideInformationFooterOutToLeft: slideInformationFooterOutToLeft,
    slideInformationFooterInFromLeft: slideInformationFooterInFromLeft,
	slideMediaItemInFromLeft: slideMediaItemInFromLeft,
	slideMediaItemInFromRight: slideMediaItemInFromRight,
}
