import * as COOKIE from './Helpers/Cookie';
import QueryString from 'lib/Helpers/QueryString';
import MakeCookieOption from './Helpers/MakeCookieOption';
import Parallax from 'lib/Vendor/Parallax';


var LIB = {};
LIB.COOKIE = COOKIE;

LIB.parseQueryString = QueryString(LIB);
LIB.makeCookieOption = MakeCookieOption(LIB);

LIB.parallax = Parallax


LIB.makeCookieOption('FORCE_APP_VERSION', 'string');




export default LIB
