import React from 'react'
import scrollify from 'jquery-scrollify/jquery.scrollify.js';
import {BlogDataSet} from 'data/data';
import {NavigationButtonHelper} from 'lib/Helpers/NavigationButton';
import {connectElements} from 'lib/Helpers/svgDraw';
import BlogVideoSection from 'components/Pages/Blog/blogVideo.section';
import BlogMainContent from 'components/Pages/Blog/blogMainContent.section';
const $ = window.$;

class BlogPostPage extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			
		}
		
	}

	/******Life Cycle Functions********/
	componentDidMount () {
		this.setupVerticalNavigationSideMenuJquery();
		
		$('.parallax-window').parallax({
		 	speed: 0,
		 	sliderSelector: '>.parallax-slider',
		 	mirrorSelector: '.page-blogpost',
		  	scrollingSelector: '.page-blogpost'
		})	

		NavigationButtonHelper.setColor({
			'primaryColor': 'rgba(0,0,0,0.3)',
			'secondaryColor': 'white'
		});

		NavigationButtonHelper.showBackButton();

		this.setState({
			wH: $(window).height()
		})

	}
	componentWillUnmount() {
        scrollify.instantMove(0);
        scrollify.destroy();
        $('.parallax-window').parallax('destroy');
    }
	setupVerticalNavigationSideMenuJquery () {

		var navigationItems = $('#cd-vertical-nav a');
        
		updateActiveNavigationItem();
		$('.page-blogpost').on('scroll', function(event){
			updateActiveNavigationItem();
			connectNavigationDots();
		});

		//smooth scroll to the section
		navigationItems.on('click', function(event){
	        event.preventDefault();
	        var clickedSectionIndex = parseInt($(this).attr('data-number'));
	        scrollify.move(clickedSectionIndex)
	    });
	    //smooth scroll to second section
	    $('.cd-scroll-down').on('click', function(event){
	        event.preventDefault();
	        var clickedSectionIndex = parseInt($(this).attr('data-number'));
	        scrollify.move(clickedSectionIndex)
	    });

	    //open-close navigation on touch devices
	    $('.touch .cd-nav-trigger').on('click', function(){
	    	$('.touch #cd-vertical-nav').toggleClass('open');
	  
	    });
	    //close navigation on touch devices when selectin an elemnt from the list
	    $('.touch #cd-vertical-nav a').on('click', function(){
	    	$('.touch #cd-vertical-nav').removeClass('open');
	    });

		function updateActiveNavigationItem() {
			Object.keys(navigationItems).forEach((key) => {
				if (parseInt(key) === scrollify.currentIndex()) {
					$(navigationItems[key]).addClass('is-selected')
				} else {
					$(navigationItems[key]).removeClass('is-selected');
				}
			})
		}

		function connectNavigationDots () {
			Object.keys(navigationItems).forEach((key, index) => {
				if (index < navigationItems.length - 1) {
					connectElements($('#svg1'), $('#path' + index), $('#dot-' + index), $('#dot-' + (index+1)));
				}
			})
		}

		//Draw svg 
		connectNavigationDots();
		
		$(window).resize(function () {
		    // reset svg each time 
		    $("#svg1").attr("height", "0");
		    $("#svg1").attr("width", "0");
		    connectNavigationDots();
		});
	}
	retrieveBlogData () {
		return BlogDataSet.filter((blogData) => {
			return blogData.BlogID === this.props.blogID;
		}).pop();
	}
	/********************************/
	
	/******Build Functions********/
	buildVerticalNavigationSideMenu (sections) {
		var navDotsAndLabels = sections.map((section,index) => {
			return (
				<li key={index}>
					<a href={'#section' + index} data-number={index}>
						<div style={{'width':'150px'}}>
							<div className="cd-label">{section.title}</div>
							<div className="cd-dot" id={"dot-"+index}></div>
						</div>
					</a>
				</li>
			)
		})

		var navLineSVGPaths = sections.map((section,index) => {
			return (
				<path
		            id={"path" + index}
		            d="M0 0" 
		            stroke="#9D9D9D" 
		            strokeOpacity="0.7"
		            fill="none" 
		            strokeWidth="1px"
		            key={index} />
			)
		})

		return (
			<nav id="cd-vertical-nav">
				<div id="nav-line-container">
					<svg id="svg1" >
						{navLineSVGPaths}
					</svg>
					
				</div>
				<ul>
					{navDotsAndLabels}
				</ul>
			</nav>
		)
	}

	buildVerticalNavigationSections (sections) {
		return sections.map((section,index) => {
			var sectionContent;
			switch(section.type) {
				case 'main':
					sectionContent = <BlogMainContent content={section.content} key={index} index={index}/>
					break;
				case 'video':
					sectionContent = <BlogVideoSection content={section.content} key={index} index={index}/>
					break;
				
				default:
					break;
			}
			return sectionContent;
		})
	}
	/********************************/
	

	render() {

		var blogData = this.retrieveBlogData();


		//Were not using this vertical navigation side menu right now
		//var verticalNavigationSideMenu = blogData.showSideNavigation ? this.buildVerticalNavigationSideMenu (blogData.sections)  : (<div></div>);
		//{verticalNavigationSideMenu}
		
		var verticalNavigationSections = this.buildVerticalNavigationSections (blogData.sections);	

		return (
			<div className='page-blogpost' >
				{verticalNavigationSections}
			</div>
		)
	}
}

export default BlogPostPage