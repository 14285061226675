import React from 'react'

import ProjectItem from './projectItem';
import { ProjectDataSet } from 'data/data';

import {NavigationButtonHelper} from 'lib/Helpers/NavigationButton';
const $ = window.$;

class ProjectGridPage extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			
		}
		this.buildParallaxProjectsSet = this.buildParallaxProjectsSet.bind(this);
	}

	componentDidMount () {
		NavigationButtonHelper.setColor({
			'primaryColor': 'rgba(0,0,0,0.3)',
			'secondaryColor': 'white'
		});

		var windowWidth = $(window).width();
		if (windowWidth > 544) {
			window.ParallaxHero.initialize();
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		return false;
	}

	buildParallaxProjectsSet () {
		var parallaxProjectsSet = ProjectDataSet.map(function(projectData, index){
			return (
					<ProjectItem data={ProjectDataSet[index]} key={index}/>
			)
		})
		return parallaxProjectsSet;
	}
	
	buildEndBlock () {
		return (
			<div className='projects-end-block'>
				<div className='inner-content'>
						<p1>Looking for more...</p1>
						<div>
							<span>It's a wrap</span><br></br>
							<span style={{'color':'#4E4F51'}}>Want to create</span><br></br>
							<span style={{'color':'#4E4F51'}}>great things</span><br></br>
							<span style={{'color':'#4E4F51'}}>with me?</span><br></br>
							<span>dailenspencer@gmail.com</span><br></br>
						</div>
				</div>
			</div>
		)
	}
	
	render() {
		var parallaxProjectsSet = this.buildParallaxProjectsSet()
		var endBlock = this.buildEndBlock();
		return (
			<div className="page-projectswrapper">
				{parallaxProjectsSet}
				{endBlock}
			</div>
		);
	}
}

export default ProjectGridPage