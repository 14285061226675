const $ = window.$;
export function deviceIsMobile() {
	if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) 
		|| $(window).width < 545) {
	 return true;
	}
	return false;
}

export function fadeOutAndRemoveElements(elementTags, duration) {
	elementTags.forEach(function(elementTag){
		$(elementTag).animate({opacity:0.0},duration, function(){
			$(elementTag).css('display','none');
		});
	})
}

export function fadeInAndPresentElements(elementTags, duration) {
	elementTags.forEach(function(elementTag){
		$(elementTag).css({display:'flex'});
    	$(elementTag).animate({opacity: 1.0}, duration);
	})
	
}

export function blurElements(elementTags){
	elementTags.forEach(function(elementTag){
		var filterVal = 'blur(10px)';
	    $(elementTag).css({
	        'filter':filterVal,
	        'webkitFilter':filterVal,
	        'mozFilter':filterVal,
	        'oFilter':filterVal,
	        'msFilter':filterVal,
	        'transition':'all 0.25s ease-in-out',
	        '-webkit-transition':'all 0.25s ease-in-out',
	        '-moz-transition':'all 0.25s ease-in-out',
	        '-o-transition':'all 0.25s ease-in-out'
	    }, 100);
	})
}

export function unblurElements(elementTags){
	elementTags.forEach(function(elementTag){
		var filterVal = 'blur(0px)';
	    $(elementTag).css({
	        'filter':filterVal,
	        'webkitFilter':filterVal,
	        'mozFilter':filterVal,
	        'oFilter':filterVal,
	        'msFilter':filterVal,
	        'transition':'all 0.5s ease-out',
	        '-webkit-transition':'all 0.5s ease-out',
	        '-moz-transition':'all 0.5s ease-out',
	        '-o-transition':'all 0.5s ease-out'
	    }, 100);
	})
}

export function elementIsInView(elem, isInFullView)
{
    var pageTop = $(window).scrollTop();
	var pageBottom = pageTop + $(window).height();
	var elementTop = $(elem).offset().top;
	var elementBottom = elementTop + $(elem).height();
	console.log(elementBottom <= pageBottom, elementTop >= pageTop);
	return elementBottom <= pageBottom && elementTop >= pageTop;
	
}