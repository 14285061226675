import React from 'react'
import {Route} from 'react-router-dom';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import _ from 'lodash';


import HomePage from 'components/Pages/Home/home.page';
import ParallaxPage from 'components/Pages/Home/parallax.page';
import NavigationPage from 'components/Pages/Navigation/navigation.page';
import BlogListPage from 'components/Pages/Blog/blogList.page';
import BlogPostPage from 'components/Pages/Blog/blogPost.page';
import ProjectGridPage from 'components/Pages/ProjectGrid/projectGrid.page';
import ProjectContentPage from 'components/Pages/ProjectContent/projectContent.page';
import SubscribePage from 'components/Pages/Subscribe/subscribe.page';
import AboutPage from 'components/Pages/About/about.page';
import NewAboutPage from 'components/Pages/About/new-about.page';
import SubjectLandingPage from 'components/Pages/SubjectLanding/subjectlanding.page';
//import ErrorPage from 'components/Pages/Error/error.page';
import NavigationButton from 'components/Widgets/NavigationButton';


import {Animator} from 'lib/Helpers/Animator.js';
import {determineIntroReactRouterAnimationType,determineExitReactRouterAnimationType} from 'lib/Helpers/AnimationDetector';
import {retrieveBlogData} from 'lib/Helpers/aws/index';

import LIB from 'lib/LIB';

const $ = window.$;
const TweenMax = window.TweenMax;
const CustomEase = window.CustomEase;


const parallaxConfigurations = {
	'navigation': {
		'backgroundImageUrl': '/media/parallax-master/home/tayrona-background-min.jpg',
		'blurredBackgroundImageUrl': '/media/parallax-master/home/tayrona-background-blur.jpg',
		'overlayItemImageUrls': ['media/parallax-master/home/tayrona-dailen-min.png']
	},
	'about': {
		'backgroundImageUrl': '/media/photography/aboutpage-background.jpg',
    	'overlayItemImageUrls': []
	},
	'engineering': {
		'backgroundImageUrl': '/media/parallax-master/subject-landing/subjectlanding-background.jpg',
		'blurredBackgroundImageUrl': '/media/parallax-master/subject-landing/subjectlanding-backgroudn.jpg',
		'overlayItemImageUrls': []
	},
	'acting': {
		'backgroundImageUrl': '/media/photography/aboutpage-background.jpg',
		'overlayItemImageUrls': []
	},
	'music': {
		'backgroundImageUrl': '/media/photography/jamsession.jpg',
		'overlayItemImageUrls': []
	},
	'music-about': {
		'backgroundImageUrl': '/media/photography/jamsession.jpg',
		'overlayItemImageUrls': []
	}
}

const subjectLandingLinkDataSets = {
	'engineering': [
		{
			title: 'About',
			link: 'engineering/about'
		},
		{
			title: 'Projects',
			link: 'engineering/projects'
		},
		{
			title: 'Contact',
			isContactButton: true,
		}
	],
	'music': [
		// {
		// 	title: 'About',
		// 	link: 'music/about'
		// },
		{
			title: 'Listen on Spotify',
			link: 'https://open.spotify.com/artist/52pw83Mdr6KYjEYYdMUmih?si=OO5CZ82uRA64BWyaa12yPQ',
			isExternalLink: true
		},
		{
			title: 'Listen on Apple Music',
			link: 'https://music.apple.com/us/artist/dailen-spencer/1736578740',
			isExternalLink: true
		},
		{
			title: 'Contact',
			isContactButton: true,
		}
	]
}

const aboutPageContent = {
	'engineering': {
		'header': 'Building Vision',
		'paragraph': 'Hello there, my name is Dailen Spencer. I am remote software architect and entrepreneur. At a young age, a passion for technology, design, and software development grew over me. I attended the University of Florida as a Computer Science Major. Quickly outpacing the curriculum, I left university and became the CEO & Founder of GreekLeaf, LLC. Since then, I have developed a wide range of applications for businesses across the globe and pushed code to programs with millions of users. I have worked extensively, in front-end design, database architecture, virtual reality development, machine learning, and much more. You can view a few of my recent projects in the Projects tab. If you have a project that requires development or consultation, please feel free to contact me by email - dailenspencer@gmail.com. I am a strong advocate for open-sourced education and autonomous learning. I love traveling and I hopes to inspire others to follow the unbeaten path and their dreams. Hope to talk soon!',
		'backgroundImageUrl': '/media/photography/aboutpage-background.jpg',
	},
	'music': {
		'header': 'World of Theory',
		'paragraph': 'Currently enrolled in Berklee\'s Online College of Music. More content coming soon :)',
		'backgroundImageUrl': '/media/photography/jamsession2.jpg',
	}
}


const firstChild = props => {
  const childrenArray = React.Children.toArray(props.children);
  return childrenArray[0] || null;
};

export default class Main extends React.Component {
	render() {
		
		var APP_VERSION = LIB.COOKIE.get('FORCE_APP_VERSION');
		if (APP_VERSION == 'prof') {
			return (
				<div id="Home">
					<NavigationButton/>
					<Route
						exact
						path="/"
						children={({ match, ...rest }) => (
						<TransitionGroup component={firstChild}>
							{match && <ProjectGrid {...rest} />}
						</TransitionGroup>
					)}/>
					<Route
						exact
						path="/navigation"
						children={({ match, ...rest }) => (
						<TransitionGroup component={firstChild}>
							{match && <ProjectGrid {...rest} />}
						</TransitionGroup>
					)}/>
					<Route
						exact
						path="/projects"
						children={({ match, ...rest }) => (
						<TransitionGroup component={firstChild}>
							{match && <ProjectGrid {...rest}/>}
						</TransitionGroup>
					)}/>
					<Route
						path="/projects/:id"
						children={({ match, ...rest }) => (
						<TransitionGroup component={firstChild}>
							{match && <ProjectContent {...rest} projectID={match.params.id} />}
						</TransitionGroup>
					)}/>
				</div>
			) 
		} else {
			return (
			<div id="Home">
				<NavigationButton/>
				<Route
					exact
					path="/"
					children={({ match, ...rest }) => (
					<TransitionGroup component={firstChild}>
						{match && <Navigation {...rest} parallaxConfiguration={parallaxConfigurations['navigation']}/>}
					</TransitionGroup>
				)}/>
				<Route
					exact
					path="/parallax"
					children={({ match, ...rest }) => (
					<TransitionGroup component={firstChild}>
				   		{match && <Parallax {...rest} />}
				 	</TransitionGroup>
				)}/>
				<Route
					exact
					path="/new-about"
					children={({ match, ...rest }) => (
					<TransitionGroup component={firstChild}>
						{match && <NewAbout {...rest} parallaxConfiguration={parallaxConfigurations['about']}/>}
					</TransitionGroup>
				)}/>

				{
					/*****************
					ENGINEERING SECTION  
					*******************/
				}
				<Route
					exact
					path="/engineering"
					children={({ match, ...rest }) => (
					<TransitionGroup component={firstChild}>
						{match && <Subject {...rest} parallaxConfiguration={parallaxConfigurations['engineering']} linkDataSet={subjectLandingLinkDataSets['engineering']}/>}
					</TransitionGroup>
				)}/>
				<Route
					exact
					path="/engineering/about"
					children={({ match, ...rest }) => (
					<TransitionGroup component={firstChild}>
						{match && <NewAbout {...rest} parallaxConfiguration={parallaxConfigurations['about']} content={aboutPageContent['engineering']}/>}
					</TransitionGroup>
				)}/>
				<Route
					exact
					path="/engineering/projects"
					children={({ match, ...rest }) => (
					<TransitionGroup component={firstChild}>
						{match && <ProjectGrid {...rest}/>}
					</TransitionGroup>
				)}/>

				{
					/*****************
					MUSIC SECTION  
					*******************/
				}

				<Route
					exact
					path="/music"
					children={({ match, ...rest }) => (
					<TransitionGroup component={firstChild}>
						{match && <Subject {...rest} parallaxConfiguration={parallaxConfigurations['music']} linkDataSet={subjectLandingLinkDataSets['music']}/>}
					</TransitionGroup>
				)}/>
				<Route
					exact
					path="/music/about"
					children={({ match, ...rest }) => (
					<TransitionGroup component={firstChild}>
						{match && <NewAbout {...rest} parallaxConfiguration={parallaxConfigurations['music-about']} content={aboutPageContent['music']}/>}
					</TransitionGroup>
				)}/>
				
				
				
				<Route
					path="/home"
					children={({ match, ...rest }) => (
				 	<TransitionGroup component={firstChild}>
				   		{match && <Home {...rest} />}
				 	</TransitionGroup>
				)}/>
				<Route
					path="/navigation"
					children={({ match, ...rest }) => (
				 	<TransitionGroup component={firstChild}>
				   		{match && <Navigation {...rest} parallaxConfiguration={parallaxConfigurations['navigation']}/>}
				 	</TransitionGroup>
				)}/>
				<Route
					exact
					path="/blog"
					children={({ match, ...rest }) => (
					<TransitionGroup component={firstChild}>
						{match && <BlogList {...rest} />}
					</TransitionGroup>
				)}/>
				<Route
					path="/blog/:id"
					children={({ match, ...rest }) => (
					<TransitionGroup component={firstChild}>
						{match && <BlogPost {...rest} blogID={match.params.id}/>}
					</TransitionGroup>
				)}/>
				<Route
					exact
					path="/projects"
					children={({ match, ...rest }) => (
					<TransitionGroup component={firstChild}>
						{match && <ProjectGrid {...rest}/>}
					</TransitionGroup>
				)}/>
				<Route
					path="/projects/:id"
					children={({ match, ...rest }) => (
					<TransitionGroup component={firstChild}>
						{match && <ProjectContent {...rest} projectID={match.params.id} />}
					</TransitionGroup>
				)}/>
				<Route
					path="/subscribe"
					children={({ match, ...rest }) => (
					<TransitionGroup component={firstChild}>
						{match && <Subscribe {...rest} />}
					</TransitionGroup>
				)}/>
				<Route
					path="/about"
					children={({ match, ...rest }) => (
					<TransitionGroup component={firstChild}>
						{match && <About {...rest} />}
					</TransitionGroup>
				)}/>
				<Route
					path="/contact"
					children={({ match, ...rest }) => (
					<TransitionGroup component={firstChild}>
						{match && <About {...rest} scrollTo={'.section-contact'}/>}
					</TransitionGroup>
				)}/>
			</div> 
		);
		}
		
		
	}
}


const AnimatedWrapper = (WrappedComponent, ComponentSelector) => class AnimatedWrapper
 extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			windowWidth: $(window).width(),
			windowHeight: $(window).height()
		}
	}

	
	

	componentWillEnter(callback) {

		this.introAnimationType = determineIntroReactRouterAnimationType(this.props.location.originPath, this.props.location.targetPath)
		
		$(ComponentSelector).css({'z-index':'10'})
		
		switch (this.introAnimationType) {
			case 'slideInFromTop':
				Animator.slideInFromTop.prepareForAnimation(ComponentSelector, this.state.windowHeight);
				break;
			case 'slideInFromBottom':
				Animator.slideInFromBottom.prepareForAnimation(ComponentSelector, this.state.windowHeight);
				break;
			default:
				if (this.props.history.action === 'POP' || this.props.location.pathname === '/navigation') {
					Animator.slideInFromLeft.prepareForAnimation(ComponentSelector, this.state.windowWidth);
				} else {
					Animator.slideInFromRight.prepareForAnimation(ComponentSelector, this.state.windowWidth);
				}
				break;
		}
		


		
		callback();
	}

	componentDidEnter () {

		$(ComponentSelector).addClass('transitioning');

		switch (this.introAnimationType) {
			case 'slideInFromTop':
				Animator.slideInFromTop.performAnimation(ComponentSelector, this.state.windowHeight);
				break;
			case 'slideInFromBottom':
				Animator.slideInFromBottom.performAnimation(ComponentSelector, this.state.windowHeight);
				break;
			default:
				if (this.props.history.action === 'POP' || this.props.history.location.pathname === '/navigation') {
					Animator.slideInFromLeft.performAnimation(ComponentSelector, this.state.windowWidth);
				} else {
					Animator.slideInFromRight.performAnimation(ComponentSelector, this.state.windowWidth);
				}
				break;
		}
	}


	componentWillLeave(callback) {
		var exitAnimation = determineExitReactRouterAnimationType(this.props.location.pathname,this.props.history.location.pathname)
		switch (exitAnimation) {
			case 'slideOutToTop':
				TweenMax.to($(ComponentSelector), 1.05,
					{
						y: -this.state.windowHeight,
						ease: CustomEase.create("custom", "M0,0,C0.173,0,0.242,0.036,0.322,0.13,0.401,0.223,0.449,0.367,0.502,0.506,0.546,0.622,0.62,0.824,0.726,0.916,0.799,0.98,0.869,1,1,1"),
						onComplete: function () {
							callback()
						}
			  		}
				);
				break
			case 'slideOutToBottom':
				TweenMax.to($(ComponentSelector), 1.05,
					{
						y: this.state.windowHeight,
						ease: CustomEase.create("custom", "M0,0,C0.173,0,0.242,0.036,0.322,0.13,0.401,0.223,0.449,0.367,0.502,0.506,0.546,0.622,0.62,0.824,0.726,0.916,0.799,0.98,0.869,1,1,1"),
						onComplete: function () {
							callback()
						}
			  		}
				);
				break;
			default:
				if (this.props.history.action === 'POP' || this.props.history.location.pathname === '/navigation') {
					TweenMax.to($(ComponentSelector), 1.05,
						{
							x: this.state.windowWidth,
							ease: CustomEase.create("custom", "M0,0,C0.173,0,0.242,0.036,0.322,0.13,0.401,0.223,0.449,0.367,0.502,0.506,0.546,0.622,0.62,0.824,0.726,0.916,0.799,0.98,0.869,1,1,1"),
							onComplete: function () {
								callback()
							}
				  		}
					);
					
				} else {
					TweenMax.to($(ComponentSelector), 1.05,
						{
							x: -this.state.windowWidth,
							ease: CustomEase.create("custom", "M0,0,C0.173,0,0.242,0.036,0.322,0.13,0.401,0.223,0.449,0.367,0.502,0.506,0.546,0.622,0.62,0.824,0.726,0.916,0.799,0.98,0.869,1,1,1"),
							onComplete: function () {
								callback()
							}
				  		}
					);
					
				}
				break;
		}
		

		
	}

	render () {
		return (
			<div className='animated-page-wrapper'>
				<WrappedComponent {...this.props} />
			</div>
		)
	}
}








class HomeComponent extends React.Component {
	render () {
		return (
			<div className="page-slider-slide page-active home">
				<HomePage/>
			</div>
		)
	}
}
const HomeComponentSelector = '.page-slider-slide.page-active.home'
const Home = AnimatedWrapper(HomeComponent, HomeComponentSelector, 'right');

class ParallaxComponent extends React.Component {
	render () {
		return (
			<div className="page-slider-slide page-active parallax">
				<ParallaxPage/>
			</div>
		)
	}
}
const ParallaxComponentSelector = '.page-slider-slide.page-active.home'
const Parallax = AnimatedWrapper(ParallaxComponent, ParallaxComponentSelector, 'right');


class NavigationComponent extends React.Component {
	render() {
		return (
			<div className="page-slider-slide page-active navigation">
				<NavigationPage {...this.props}/>
			</div>
		)
	}
}
const NavigationComponentSelector = '.page-slider-slide.page-active.navigation'
const Navigation = AnimatedWrapper(NavigationComponent, NavigationComponentSelector);


class SubjectLandingComponent extends React.Component {
	render() {
		
		return (
			<div className="page-slider-slide page-active subjectlanding">
				<SubjectLandingPage {...this.props}/>
			</div>
		)
	}
}
const SubjectLandingComponentSelector = '.page-slider-slide.page-active.subjectlanding'
const Subject = AnimatedWrapper(SubjectLandingComponent, SubjectLandingComponentSelector);

/*Blog Component*/
/*Page that holds the list of blog items*/
class BlogListComponent extends React.Component {

	render() {
		return (
			<div className="page-slider-slide page-active blog-list">
				<BlogListPage/>
			</div>
		)
	}
}
const BlogListComponentSelector = '.page-slider-slide.page-active.blog-list'
const BlogList = AnimatedWrapper(BlogListComponent, BlogListComponentSelector);



/*Blog Content Component*/
/*Page that holds the actual contents of a blog item*/
class BlogPostComponent extends React.Component {

	componentDidMount () {
		//HACK: needed to add this so parallax.js will update/refresh at appropriate time when transitioning from bloglist page to blogpost page
		var count = 0;
		var initWindowResize = _.throttle(function(){
    		if (count > 5 && !$('.transitioning').length) { //disconnect observer when page is done transitining
    			$(window).trigger('resize').trigger('scroll')
    			observer.disconnect();
    		} else if (count > 2) { //for some reason, we cant trigger the resize/scroll event for two counts. If we do it before, parallax.js will get funky
    			if ($('.parallax-window') && $('.parallax-window').data('__parallax')){
    				$('.parallax-window').data('__parallax').o.aspectRatio = null;
    				$(window).trigger('resize').trigger('scroll')
    			}
    		}
    		count++;
		}, 10)

		var observer = new MutationObserver((mutations) => {
		    mutations.forEach((mutationRecord) => {
		       initWindowResize();
		    });    
		});

		var target = document.getElementById('blog-post');
		observer.observe(target, { attributes : true, attributeFilter : ['style'] });


	}

	



	render() {
		
		return (
			<div className="page-slider-slide page-active blog-post" id="blog-post">
				<BlogPostPage {...this.props}/>
			</div>
		)
	}
}
const BlogPostComponentSelector = '.page-slider-slide.page-active.blog-post'
const BlogPost = AnimatedWrapper(BlogPostComponent, BlogPostComponentSelector);

class ProjectGridComponent extends React.Component {
	render() {
		return (
			<div className="page-slider-slide page-active projects">
				<ProjectGridPage/>
			</div>
		)
	}
}
const ProjectsGridComponentSelector = '.page-slider-slide.page-active.projects'
const ProjectGrid = AnimatedWrapper(ProjectGridComponent, ProjectsGridComponentSelector);

class ProjectContentComponent extends React.Component {
	render() {
		return (
			<div className="page-slider-slide page-active project-content">
				<ProjectContentPage {...this.props}/>
			</div>
		)
	}
}
const ProjectContentComponentSelector = '.page-slider-slide.page-active.project-content'
const ProjectContent = AnimatedWrapper(ProjectContentComponent, ProjectContentComponentSelector);

class SubscribeComponent extends React.Component {
	render () {
		return (
			<div className="page-slider-slide page-active subscribe">
				<SubscribePage/>
			</div>
		)
	}
}
const SubscribeComponentSelector = '.page-slider-slide.page-active.subscribe'
const Subscribe = AnimatedWrapper(SubscribeComponent, SubscribeComponentSelector);


class AboutComponent extends React.Component {
	render() {
		return (
			<div className="page-slider-slide page-active about">
				<AboutPage {...this.props}/>
			</div>
		)
	}
}
const AboutComponentSelector = '.page-slider-slide.page-active.about';
const About = AnimatedWrapper(AboutComponent, AboutComponentSelector);

class NewAboutComponent extends React.Component {
	render() {
		return (
			<div className="page-slider-slide page-active new-about">
				<NewAboutPage {...this.props}/>
			</div>
		)
	}
}
const NewAboutComponentSelector = '.page-slider-slide.page-active.new-about';
const NewAbout = AnimatedWrapper(NewAboutComponent, NewAboutComponentSelector);

/*Commenting out until in use*/
// class ErrorComponent extends React.Component {
// 	render() {
// 		return (
// 			<div className="page-slider-slide page-active error">
// 				<ErrorPage/>
// 			</div>
// 		)
// 	}
// }
// const ErrorComponentSelector = '.page-slider-slide.page-active.error';
// const Error = AnimatedWrapper(ErrorComponent, ErrorComponentSelector);