
import React from 'react'
import {deviceIsMobile} from 'lib/Helpers/JqueryHelpers';
import LIB from 'lib/LIB';
const parallaxSettings = {
	dataDepth: deviceIsMobile() ? {'background':'0.15','item':'0.25'} :  {'background':'0.05','item':'0.1'}
}

//TODO: add a placeholder to 
export default class ParallaxBackground extends React.Component {

    constructor(props) {
        super(props);        
        this.state = {
            newImageLoaded: false
        }
    }
	
	componentDidMount () {
        setTimeout(() => {
            const { parallax } = LIB;
            var scene = document.getElementById('scene');
            window.parallaxInstance = new parallax(scene);
        },1100)


        const { parallaxConfiguration } = this.props;
        const { backgroundImageUrl, overlayItemImageUrls } = parallaxConfiguration;

        const imageUrls = overlayItemImageUrls.concat(backgroundImageUrl).filter((imageUrl) => imageUrl !== "")

        imageUrls.forEach((imageUrl, index) => {
            var img = new Image();
            img.src = imageUrl;
            img.onload = () => {                
                window.loadedImages.push(imageUrl);
                this.setState({newImageLoaded: true})
            }
        })

    }


    componentWillUnmount () {
        //Removed because it was causing bug where getAttribute was null in Parallax.js
        // window.parallaxInstance.destroy()
    }
	
	render() {        
        const { parallaxConfiguration } = this.props;
        const { blurredBackgroundImageUrl, overlayItemImageUrls } = parallaxConfiguration;
        var { backgroundImageUrl } = parallaxConfiguration;
        
        const backgroundImageLoaded = ~window.loadedImages.indexOf(backgroundImageUrl)

        //If there is a background image url, and it has loaded, then use that as the background image
        if (!backgroundImageLoaded) {
            if (blurredBackgroundImageUrl) {
                backgroundImageUrl = blurredBackgroundImageUrl;
            } else {
                backgroundImageUrl = '';
            }
        }
		
		return (
            <ul id="scene" className="scene unselectable" data-friction-x="0.1" data-friction-y="0.1" data-scalar-x="25" data-scalar-y="15">
                <li className="layer" data-depth={parallaxSettings.dataDepth.background}><div className="parallax-background" style={{'backgroundImage': `url(${backgroundImageUrl})`}}></div></li>
                {
                    
                    overlayItemImageUrls.map((overlayItemImageUrl, index) => {
                        const overlayItemImageLoaded = ~window.loadedImages.indexOf(overlayItemImageUrl)
                        const overlayItemStyle = {
                            'backgroundImage': `url(${overlayItemImageLoaded ? overlayItemImageUrl : ''})`
                        }
                        return (
                            <li className="layer" data-depth={parallaxSettings.dataDepth.item} key={index}>
                                <div className="parallax-item" style={overlayItemStyle}></div>
                            </li>
                        )
                    })
                }
                
            </ul>
		);
	}
}