
import React from 'react'
import Plyr from 'plyr';
const $ = window.$;

export default class VideoWidget extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			videoPlayer: null
		}
	}	
    
    /******Life Cycle Functions********/
    componentDidMount () {
        const videoPlayer = new Plyr('#'+this.props.elementId, this.props.videoPlayerOptions);
        this.setState({videoPlayer: videoPlayer})


        //THESE WONT WORK BECAUSE ITS GRABBING ALL ELEMENTS WITH THE CLASS

        //hide video player wrapper to show background
        if (this.props.transparentBackground) $('.plyr__video-wrapper').css('opacity','0.0');

        videoPlayer.on('playing', event => {
            //show video content when user plays video
            if (this.props.transparentBackground) $('.plyr__video-wrapper').css('opacity','1.0');
        });

        videoPlayer.on('pause', event => {
            //hide video player wrapper to show background
            if (this.props.transparentBackground) $('.plyr__video-wrapper').css('opacity','0.0');
        });

        //Event listeners for loading start/end
        videoPlayer.on('loadstart', event => {
            if (!this.props.onSeeking) return;
            this.props.onSeeking(this.state.videoPlayer);
        })

        videoPlayer.on('statechange', event => {
            switch (event.detail.code) {
                case -1:
                    break;
                case 0:
                    break;
                case 1:
                    if (!this.props.onPlaying) return;
                    this.props.onPlaying(this.state.videoPlayer);
                    break;
                case 2:
                    break;
                case 3:
                    if (!this.props.onBuffering) return;
                    this.props.onBuffering(this.state.videoPlayer);
                    break;
                case 5:
                    if (!this.props.onCued) return;
                    this.props.onCued(this.state.videoPlayer);
                    break;
                default:
                    break;
            }
            if (!this.props.onSeeking) return;
            this.props.onSeeking(this.state.videoPlayer);
        })
    }

    /******Toggle Functions********/
    handleClick () {
        if (!this.props.onClick) return;
        this.props.onClick(this.state.videoPlayer);
    }

    handleMouseOver () {
        if (!this.props.onMouseOver) return;
        this.props.onMouseOver(this.state.videoPlayer);
    }

    handleMouseOut () {
        if (!this.props.onMouseOut) return;
        this.props.onMouseOver(this.state.videoPlayer);
    }

    
    
    render() {
        //Return appropriate HTML for Plyr.io - see https://github.com/sampotts/plyr docs
        if (this.props.videoPlayerData.origin == 'youtube' || this.props.videoPlayerData.origin == 'vimeo') {
            return (
                <div className='video-widget'>
                   <div className="plyr__video-embed" id={this.props.elementId} onClick={() => this.handleClick ()} onMouseOver={() => this.handleMouseOver ()} onMouseOut={() => this.handleMouseOut ()}>
                       <iframe src={this.props.videoPlayerData.src}></iframe>
                   </div>
                </div>
            )
        } else {
            return (
                <div className='video-widget'>
                    <video className="plyr__video-embed" id={this.props.elementId} onClick={() => this.handleClick ()} onMouseOver={() => this.handleMouseOver ()} onMouseOut={() => this.handleMouseOut ()} playsInline>
                        <source src={this.props.videoPlayerData.src} type="video/mp4"/>
                    </video>
                </div>
                
            )
        }
         
	}
}