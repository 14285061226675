const $ = window.$;

( function($) {

	module.exports = function () {
		
		var MoveIt = {}

		MoveIt.initialize = function () {
			$('[data-scroll-speed]').moveIt();
		}

		//ToDo: move `moveIt` to lib
		$.fn.moveIt = function(){
			var instances = [];
			  
			$(this).each(function(){
			  instances.push(new moveItItem($(this)));
			});


			$('.page-contentpage').on('scroll', function (event) {
				//ToDo: change this so we only grab parent .page-contet-page
				var scrollTop = $(event.target).scrollTop();
				instances.forEach(function(inst){
				    inst.update(scrollTop);
				});

				//Do we really want this? 
				// $('.background').css('opacity', 1 - (scrollTop / 500));
			})

			$('.page-slider-slide.page-active.about .page-about').on('scroll', function (event) {
				var scrollTop = $(event.target).scrollTop();
				instances.forEach(function(inst){
				    inst.update(scrollTop);
				});
				//Do we really want this? 
				$('.wrapper').css('opacity', 1 - (scrollTop / 500));
			})
		}

		var moveItItem = function(el){
		    this.el = $(el);
		    this.speed = parseInt(this.el.attr('data-scroll-speed'));
		};

		moveItItem.prototype.update = function(scrollTop){	

			var scrollHeight;
			
			if (this.el[0].className === 'content-board') {
				scrollHeight = $('.page-contentpage')[0].scrollHeight;
				if (scrollTop < scrollHeight - 700) {
					this.el.css('transform', 'translateY(' + -(scrollTop / this.speed) + 'px) translateX(' + '-50%');
				}
			} else if ($('.page-about').length) {
				scrollHeight = $('.page-about')[0].scrollHeight;
				if ((scrollTop < scrollHeight - 700) && (scrollTop > -1)) {
					this.el.css('transform', 'translateY(' + -(scrollTop / this.speed) + 'px)');
				}
			} else {
				this.el.css('transform', 'translateY(' + -(scrollTop / this.speed) + 'px)');
			}
			
		};
		
		return MoveIt;
	}

} ) ( $ );