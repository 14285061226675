import React from 'react'
import TextField from 'material-ui/TextField';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import * as EmailActions from 'actions/EmailActions';
const $ = window.$;

const Form = (SubmissionType) => class Form extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			submissionStatus: {'status': 'none', 'message': ''},
			nameField: {'value': '', 'error': ''},
			emailField: {'value': '', 'error': ''},
			messageField: {'value':'', 'error': ''}
		}

		this.encode = this.encode.bind(this);
		
	}

	

	resetPage () {
		this.setState({submissionStatus: {'status': 'none', 'message': ''}})
	}

	encode (data) {
	    return Object.keys(data)
	        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
	        .join("&");
  	}


	handleSubmit (e) {
		
		this.setState({submissionStatus:{'status':'loading','message':''}});
		
		var $form = $(this);
		

		const handleEmailSubmit = () => {
			if (this.state.emailField.value.length < 1) {
				this.handleTextValidationError('email')
				return;
			}
			if (this.state.nameField.value.length < 1) {
				this.handleTextValidationError('name');
				return;
			}
			
			const formData = {
				name: this.state.nameField.value,
				email: this.state.emailField.value
			}

			setTimeout(() => {
				debugger
				fetch("/", {
			        method: "POST",
			        headers: { "Content-Type": "application/x-www-form-urlencoded" },
			        body: this.encode({ "form-name": "email", ...formData })
			    })
		        .then(() => this.handleSubmissionSuccess())
		        .catch(error => this.handleSubmissionError(error));
			},1500)
		}
		
		const handleMessageSubmit = () => {
			var validated = true;
			if (this.state.emailField.value.length < 1) {
				this.handleTextValidationError('email')
				return;
			}
			if (this.state.nameField.value.length < 1) {
				this.handleTextValidationError('name');
				return;
			}
			if (this.state.messageField.value.length < 1) {
				this.handleTextValidationError('message');
				return;
			}

			const formData = {
				name:this.state.nameField.value,
				email:this.state.emailField.value,
				message:this.state.messageField.value
			}
			
			setTimeout(() => {

				fetch("/", {
			        method: "POST",
			        headers: { "Content-Type": "application/x-www-form-urlencoded" },
			        body: this.encode({ "form-name": "contact", ...formData })
			    })
		        .then(() => this.handleSubmissionSuccess())
		        .catch(error => this.handleSubmissionError(error));
			},1500)
			
		}

		
		switch (SubmissionType) {
			case 'message':
				handleMessageSubmit();
				break;
			case 'email':
				handleEmailSubmit();
				break;
			default:
				break;
		}
		

		
		
	}

	

	handleSubmissionSuccess () {
		this.setState({
			submissionStatus: {'status': 'complete', 'message': 'Email Saved!'},
			nameField: {'value': '', 'error': ''},
			emailField: {'value': '', 'error': ''},
			messageField: {'value': '', 'error': ''}
		});
		
		setTimeout(() => {
			this.resetPage();
		},3000)
		
	}

	handleSubmissionError (error) {
		this.setState({submissionStatus: {'status': 'error', 'message': '...Try Again'}});
	}

	handleTextValidationError (erroredField) {
		const errorText = 'Bummer! Please fill out this field';
		switch (erroredField) {
			case 'email':
				this.setState({emailField: {value: this.state.emailField.value, error: errorText}})
				break;
			case 'name':
				this.setState({nameField: {value: this.state.nameField.value, error: errorText}})
				break;
			case 'message':
				this.setState({messageField: {value: this.state.messageField.value, error: errorText}})
				break;
			default:
				break;
		}
		this.setState({submissionStatus: {'status': 'error', 'message': '...Try Again'}});
	}

	handleNameFieldChange (e) {
		this.setState({nameField: {value: e.target.value, error: ''}});
	}

	handleEmailFieldChange (e) {
		this.setState({emailField: {value: e.target.value, error: ''}});
	}

	handleMessageFieldChange (e) {
		this.setState({messageField: {value: e.target.value, error: ''}});
	}

	render() {
		
		const muiTheme = getMuiTheme({
		    palette: {
		        primary1Color: this.props.color,
		        primary2Color: this.props.color,
		        accent1Color: this.props.color,
		        pickerHeaderColor: this.props.color,
		        textColor: this.props.color
		    },
		});
		
		var submit = 'Submit';
		if (this.state.submissionStatus.status === 'loading') {
			submit = <img src='/media/loaders/three-dots.svg' alt="loading"/>
		} else if (this.state.submissionStatus.status === 'complete') {
			submit = 'Complete'
		} else if (this.state.submissionStatus.status === 'error') {
			submit = this.state.submissionStatus.message
		}

		
		return (

			<div className='form'>
				<div className='fields'>					
					<MuiThemeProvider muiTheme={muiTheme}>
						<TextField
						  style={this.props.textFieldStyle.nameField}
						  value={this.state.nameField.value}
						  errorText={this.state.nameField.error}
						  floatingLabelText="Name"
						  floatingLabelStyle={{"color":this.props.color,"fontFamily":"'Roboto', sans-serif","fontWeight":"300"}}
					      hintStyle={{"color":this.props.color,"fontFamily":"'Roboto', sans-serif","fontWeight":"300"}}
					      inputStyle={{'color':this.props.color,"fontFamily":"'Roboto', sans-serif","fontWeight":"300"}}
					      onChange={this.handleNameFieldChange.bind(this)}
					    />
				    </MuiThemeProvider>
				    <MuiThemeProvider muiTheme={muiTheme}>
						<TextField
						  style={this.props.textFieldStyle.emailField}
						  value={this.state.emailField.value} 
						  errorText={this.state.emailField.error}
						  floatingLabelText="Email"
						  floatingLabelStyle={{"color":this.props.color,"fontFamily":"'Roboto', sans-serif","fontWeight":"300"}}
					      hintStyle={{"color":this.props.color,"fontFamily":"'Roboto', sans-serif","fontWeight":"300"}}
					      inputStyle={{'color':this.props.color,"fontFamily":"'Roboto', sans-serif","fontWeight":"300"}}
					      onChange={this.handleEmailFieldChange.bind(this)}
					    />
				    </MuiThemeProvider>
				     <MuiThemeProvider muiTheme={muiTheme}>
						<TextField
						  style={this.props.textFieldStyle.messageField}
						  value={this.state.messageField.value} 
						  errorText={this.state.messageField.error}
						  floatingLabelText="Your Message Here"
						  floatingLabelStyle={{"color":this.props.color,"fontFamily":"'Roboto', sans-serif","fontWeight":"300"}}
					      hintStyle={{"color":this.props.color,"fontFamily":"'Roboto', sans-serif","fontWeight":"300"}}
					      inputStyle={{'color':this.props.color,"fontFamily":"'Roboto', sans-serif","fontWeight":"300"}}
					      multiLine={true}
					      onChange={this.handleMessageFieldChange.bind(this)}
					    />
				    </MuiThemeProvider>
				</div>
				<div className='submit' onClick={(e) => this.handleSubmit(e)}>{submit}</div>
			</div>
		);
	}
}
export default Form;