import React from 'react';
import LIB from 'lib/LIB';
import 'styles/main.scss';

export default class App extends React.Component {
	componentWillMount () {
		// if (window.location.hostname == 'dailenspencer.com') {
		// 	window.FORCE_APP_VERSION('prof');
		// }

		//Storage to ensure we dont load images multiple times
		if (!window.loadedImages) {
			window.loadedImages = [];
		}
	}
	render() {
		return (
			<div id="App" style={{'height':'100%','width':'100%'}}>
		        	{this.props.children} 
			</div>
		);
	}
}



