export var DefaultDataItem = [
	{
		'DataType': 'project',
		'ProjectTimeline':'2015',
		'ProjectLogo': 'media/logos/ClickTripz.png',
		'SkillSet': {
			'swift':'media/skillsets/Swift.png',
			'xcode':'media/skillsets/xcode.png',
			'node':'media/skillsets/nodejs.png',
			'css':'media/skillsets/css3.png',
			'html5':'media/skillsets/HTML5.png',
			'javascript':'media/skillsets/Javascript.png',
			'react':'media/skillsets/react.png',
		},
		'WebsiteData': {
			'link':'http://www.google.com/',
		},
		'ProjectDescription': `Example Description`,
		'HoverMenuData': {
			'title': 'Example Title',
			'snippet': 'Example Snippet',
			'styles': {
				'hoverColor': 'rgba(0,0,0,0.6)',
				'headerColor': '#ffffff',
				'snippetColor': '#ffffff'
			},
			'backgroundImages': {
				'image1': '../media/Parallax-Hero/GreekLeaf/GreekLeaf_Phone_DarkSurface.jpg',
			}
		},
		'ContentPageData': {
			'background': {
				'imageURL': '../../media/photography/SurfersDownHill1.png',
				'color': '#0F1003'
			},
			'board': {
				'title': 'GreekLeaf, LLC',

				'position': 'Chief Executive Officer',
				'type': 'iOS & Web Application',
				'brief': 'Why save a single forest when you can save 100 forests? This is the philosophy of The Nature Conservancy of California, one of the world’s most influential and reliable nonprofit organizations. We created a site experience that showcases the agency’s amazing project content and gave them the digital tools to continue into the future.',
				'story': `TNC’s mission is to find ways for both nature and people to thrive, together. They find real-world solutions in California, and use them to solve big, ecological problems around the world. To help support the launch of a new program aimed at acquiring and engaging younger donors, they needed a website that could showcase all the innovative work they’re doing across the globe.
							So we created a platform with immersive, longform storytelling at its core. Ambient video, imagery, audio clips, and sound design come together to let users dive into projects head-first. The entire experience–from sound to color–was inspired by the gorgeous nature of California. We worked with a composer to create unique soundscapes for each story chapter, placing users in the heart of the action, and hopefully, closer to supporting the Conservancy.`,
				'deliverables': ['Online Marketing', 'Social Media Management','Swift', 'xCode', 'Parse', 'HTML', 'CSS3', 'Javascript', 'React', 'Node', 'Photoshop', 'Illustrator'],
				'styles': {
					'buttonColor': 'white',
					'headerColor': 'white',
					'backgroundColor': 'rgba(0,0,0,0.7)',
					'positionColor': 'white',
					'titleColor': 'white',
					'typeColor': 'white',
					'briefColor': 'white',
					'storyColor': 'white',
					'skillColor': 'white'
				}
				
			}
		}
	}
]