import React from 'react'
import SocialWidget from 'components/Widgets/SocialWidget';
import { withRouter} from 'react-router-dom';
import {deviceIsMobile} from 'lib/Helpers/JqueryHelpers';
import {NavigationButtonHelper} from 'lib/Helpers/NavigationButton';
import ParallaxBackground from '../../Widgets/ParallaxBackground';

const Parallax = window.Parallax;

var navItems = [
	{
		'title':'Home',
		'primaryColor': '#0BB494',
		
	},
	// {
	// 	'title':'About',
	// 	'primaryColor': '#FF217C',
	// },
	// {
	// 	'title':'About',
	// 	'primaryColor': '#FF217C',
	// },
	// {
	// 	'title':'Engineering',
	// 	'primaryColor': '#4392F1',
	// },
	{
		'title':'Projects',
		'primaryColor': '#FFA283',
	},
	{
		'title':"Resume",
		'primaryColor': '#FF217C',
		'link': 'https://docs.google.com/document/d/1VWh2ACmudAlPWgiQo20AdaZGeYiMY3Nb4jY7qME5Tz0/edit?usp=sharing'
	},
	{
		'title':"Music",
		'primaryColor': '#337CA0',
	},
	// {
	// 	'title':'Lets Talk',
	// 	'primaryColor': '#00DFD4',
	// },
	// {
	// 	'title':"Blog",
	// 	'primaryColor': '#736B9A',
	// },
	// {
	// 	'title':'Live Stream',
	// 	'primaryColor': '#0DFFE2',
	// 	'link': 'http://www.twitch.tv/hellodailen'
	// },
	{
		'title':"Featured News",
		'primaryColor': '#FF217C',
		'link': 'http://tucmag.net/technology/entrepreneurship-a-new-way-of-learning-and-living'
	},
	

]


class NavigationPage extends React.Component {
	
	constructor(props){
		super(props)
		this.state = {
			activeElement: navItems[0],
			hoveredElement:navItems[0]
		}
		this.handleNavigationChange = this.handleNavigationChange.bind(this);
	}

	/******Life Cycle Functions********/
	shouldComponentUpdate(nextProps, nextState) {
		return true;
	}
	componentDidMount() {
		NavigationButtonHelper.setColor({primaryColor:'rgba(0,0,0,0.0)'});

		var scene = document.getElementById('scene');
		var parallaxInstance = new Parallax(scene);
	}
	/************************************/

	/******Build Functions********/
	buildNavItems () {
		return navItems.map((navigationItem, index) => {
			var style = {}
			if (this.state.hoveredElement && this.state.hoveredElement.title === navigationItem.title) {
				style.color = this.state.hoveredElement.primaryColor;
			} else if (this.state.activeElement.title === navigationItem.title) {
				style.color = this.state.activeElement.primaryColor
			} else {
				style.color = 'rgb(255,255,255)'
			}

			return (
				<div style={style} className={'nav-item '} onClick={() => this.handleNavigationChange(navigationItem)} onMouseOver={() => this.handleNavigationItemMouseOver(navigationItem)} onMouseLeave={() => this.handleNavigationItemMouseLeave()} key={index}>{navigationItem.title}</div>
			)
		})
	}
	/************************************/
	
	/******Toggle Functions********/
	handleNavigationChange (navigationItem) {
		//If we are clicking on a third party link, open link in new tab and return
		if (navigationItem.link) {
			window.open(navigationItem.link,'_blank')
			return;
		}

		switch (navigationItem.title) {
			case 'Home':
				this.props.history.push('/home');
				break;
			case 'Parallax':
				this.props.history.push('/parallax');
				break;
			case 'Projects':
				this.props.history.push('/projects');
				break;
			// case 'About':
			// 	this.props.history.push('/about');
			// 	break;
			case 'Music':
				this.props.history.push('/music');
				break;
			case 'New About':
				this.props.history.push('/new-about');
				break;
			case 'Engineering':
				this.props.history.push('/engineering');
				break;
			case 'Blog':
				this.props.history.push('/blog');
				break;
			case 'Lets Talk':
				this.props.history.push('/contact');
				break;
			default:
				break;
		}
	
	}
	handleNavigationItemMouseOver (navigationItem) {
		this.setState({'hoveredElement':navigationItem});
	}
	handleNavigationItemMouseLeave () {
		this.setState({'hoveredElement':this.state.activeElement})
	}
	/************************************/


	

	render() {
		const { parallaxConfiguration } = this.props;
		var navItems = this.buildNavItems();
		

		var style = {};
		if (!this.state.hoveredElement || deviceIsMobile()) {
			style.buttonColor = this.state.activeElement.primaryColor;
		} else {
			style.buttonColor = this.state.hoveredElement.primaryColor;
		}




		return (
			
				<div className='page-navigation page-parallax' style={style}>
					<ParallaxBackground parallaxConfiguration={parallaxConfiguration} />
					<div className='overlay'>
							<SocialWidget/>
						</div>
					<div className='left-section'>
						<div className='inner'>
							<div className='menu-board'>
								<div className='title'>
									<h1>Navigation</h1><div></div>
								</div>
								
								<div className='nav-menu-holder'>									
									<div className='nav-menu'>
										{navItems}
									</div>
								</div>
								<a href="mailto:dailenspencer@gmail.com" className='email'>dailenspencer@gmail.com</a>
								<div className='copyright'>© Copyright 2017</div>

							</div>
						</div>
					</div>
				</div>
			
			
		);
	}
}

export default withRouter(NavigationPage);

