import React from 'react'
import {NavigationButtonHelper} from 'lib/Helpers/NavigationButton';
import {deviceIsMobile} from 'lib/Helpers/JqueryHelpers';
import {ImageLoader} from 'lib/Helpers/ImageLoader';
import { withRouter} from 'react-router-dom';


class ProjectItem extends React.PureComponent {
	constructor(props){
		super(props)
		this.state = {
			hover: false,
			componentIsLoading: true
		}
		
	}

	componentDidMount () {	
		var projectItemImages = this.props.data.ProjectItemData.backgroundImages;
		ImageLoader.preloadImages(projectItemImages).then(() => {
			this.setState({componentIsLoading: false});
		}).catch(function(err){
			throw new Error(err);
		})
	}

	shouldComponentUpdate(nextProps, nextState) {
		if(this.state.hover !== nextState.hover ||
		   this.state.componentIsLoading !== nextState.componentIsLoading) {
			return true;
		}
		return false;
	}


	buildFloatingBackground () {

		var imageLinks = this.props.data.ProjectItemData.backgroundImages;
		var imageElements = Object.keys(imageLinks).map(function(key){
			if(!imageLinks[key] || imageLinks[key] === '') return (<img src='' key={key} alt={"floating-background_" + key}/>);
			return (
					<img src={imageLinks[key]} key={key} alt={"floating-background_" + key} />
			)
		})

		var floatingBackgroundStyle = {
			'visibility': this.state.componentIsLoading ? 'hidden' : 'visible'
		}
		return (
			<figure className='cd-floating-background' style={floatingBackgroundStyle}>
				{imageElements}
			</figure>
		)
	}

	buildHoverMenu (ProjectItemData) {
		var hoverMenuStyle = {
			'backgroundColor': ProjectItemData.styles.hoverColor
		}
		return (
			<div className="project-item-hovermenu" style={hoverMenuStyle} onMouseOver={() => this.showHoverMenu()} onMouseOut={() => this.hideHoverMenu()}>
				<div className="project-item-hovermenu-brief">
					<h1 style={{'color':ProjectItemData.styles.headerColor}}>{ProjectItemData.title}</h1>
					<span style={{'color':ProjectItemData.styles.snippetColor}}>{ProjectItemData.snippet}</span>
				</div>
				<div className="project-item-hovermenu-button">
					<img src="/media/Icons/ellipsis.png" alt="ellipsis action button"/>
				</div>	
			</div>
		)
	}

	buildMobileButton () {
		var mobileButtonStyle = {
			'visibility': this.state.componentIsLoading ? 'hidden' : 'visible'
		}
		return (
			<div className='project-item-mobile-button' style={mobileButtonStyle}>
				<img src="/media/Icons/ellipsis.png" alt="ellipsis action button"/>
			</div>
		)
	}

	showHoverMenu () {
		this.setState({hover:true})
	}
	
	hideHoverMenu () {
		this.setState({hover:false})
	}


	handleProjectItemClick (projectTitle) {
		NavigationButtonHelper.showBackButton();

		var ProjectID = this.props.data.ProjectID
		this.props.history.push('/projects/' + ProjectID);

		
	}

	render() {	
		var ProjectItemData = {}		
		ProjectItemData = {
				title: this.props.data.ProjectItemData.title,
				snippet: this.props.data.ProjectItemData.snippet,
				styles: {
					hoverColor: this.state.hover === true ? this.props.data.ProjectItemData.styles.hoverColor : 'rgba(0, 0, 0, 0)',
					headerColor: this.props.data.ProjectItemData.styles.headerColor,
					snippetColor: this.props.data.ProjectItemData.styles.snippetColor
				}
			}

		var floatingBackground = this.buildFloatingBackground();
		var mobileButton = deviceIsMobile() ? this.buildMobileButton() : '';
		var hoverMenu = deviceIsMobile() ? '' : this.buildHoverMenu(ProjectItemData);
	
		return (
			<div className="project-item" onClick={() => this.handleProjectItemClick(this.props.data.ProjectTitle)}>
				{mobileButton}
				<div className="cd-background-wrapper">
					{floatingBackground}
					{hoverMenu}
				</div>
				
			</div>
		);

  		
	}
}

export default withRouter(ProjectItem)