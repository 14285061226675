import React from 'react'
import MainButton from 'components/Widgets/MainButton';
import SocialWidget from 'components/Widgets/SocialWidget';
import BlockArrowWidget from 'components/Widgets/Buttons/BlockArrow.widget';
import { withRouter} from 'react-router-dom';
import {NavigationButtonHelper} from 'lib/Helpers/NavigationButton';
import ParallaxBackground from '../../Widgets/ParallaxBackground';
const $ = window.$;


const parallaxConfiguration = {
	'backgroundImageUrl': 'media/photography/peru-surf-compressor.jpg',
	'overlayItemImageUrls': []
}

class HomePage extends React.Component {
	
	/******Life Cycle Functions********/
	shouldComponentUpdate(nextProps, nextState) {
		return true;
	}
	componentDidMount() {
		NavigationButtonHelper.setColor()
	}
	/**********************************/

	/******Toggle Functions********** */
	handleSubscribeClick () {
		this.props.history.push({
		  pathname: '/subscribe',
		  originPath: '/home',
		  targetPath: '/subscribe'
		})
	}
	handleBlogClick () {
		//todo: move this up a higher level =
		if ($('.transitioning').length > 0) {
			return;
		}
		this.props.history.push({
		  pathname: '/blog',
		  originPath: '/home',
		  targetPath: '/blog'
		})
	}
	/**********************************/

	
	render() {
		return (
			<div className='page-homepage'>
				<ParallaxBackground parallaxConfiguration={parallaxConfiguration} />
				<div className='overlay'>
					<div className='reveal-over' onClick={() => this.handleSubscribeClick()}>
						<BlockArrowWidget onClick={null}/>
						<h1><div></div>Subscribe</h1>
					</div>
					<div className='reveal-under' onClick={() => this.handleBlogClick()}>
						<h1><div></div>Explore</h1>
						<BlockArrowWidget onClick={null}/>
					</div>
					<div className='subscribe' onClick={() => this.handleSubscribeClick()}>
						<h1>Subscribe</h1>
						<img src='media/brush/sidebrush.png' alt="side paint brush"/>
					</div>
					<SocialWidget/>
				</div>
				
				<MainButton type={'video'} position={'center'} direction={'pointRight'}/>
				
				<div className='quote'></div>
			</div>
		);
	}
}

export default withRouter(HomePage);
