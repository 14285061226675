import qs from 'qs'

const QueryString = (LIB) => {
	return function parseQueryString (queryString) {
		let parsed = {};
		try {

			queryString = queryString.replace(/^\?|\%\%/g, function(match){
				return match === '?' ? "" : "%";
			});

			parsed = qs.parse(queryString);

			return parsed;
		} catch (err) {
			//exports.forLIb would be good here because you can use its logger
			throw new Error(err);
			return null;
		}
	}
	
}

export default QueryString;