import React from 'react'
import ProjectContentBoard from './projectContentBoard';
import {ProjectDataSet} from 'data/data';
import {NavigationButtonHelper} from 'lib/Helpers/NavigationButton';
import MoveIt from 'lib/MoveIt/MoveIt';

class ProjectContentPage extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			contentData: this.retrieveProjectData()
		}
		
	}

	componentDidMount () {
		MoveIt().initialize();

		NavigationButtonHelper.setColor(this.state.contentData.NavigationStyle);
	}

	retrieveProjectData () {
		return ProjectDataSet.filter((projectData) => {
			return projectData.ProjectID === this.props.projectID
		}).pop();
	}


	buildProjectPage () {
		var contentPageStyle =  {
			backgroundColor: this.state.contentData.ContentPageData.background.color 
		}
		var contentPageBackgroundStyle = {
			backgroundImage: 'url(' + this.state.contentData.ContentPageData.background.imageURL + ')'
		}

		return (
			<div className='page-contentpage' style={contentPageStyle}>
				<div className='background box' style={contentPageBackgroundStyle} data-scroll-speed="9"></div>
				<ProjectContentBoard data={this.state.contentData} type={'project'}/>
			</div>
		);
	}
	

	render() {
		var contentPage;
		if (this.props.projectID) { //Return Project Content Page Style
			//ToDo: add attachments section below content board
			contentPage = this.buildProjectPage ();
		} 

		return (
			<div style={{'width':'100%','height':'100%','position':'absolute'}}>
				{contentPage}
			</div>
		)
	}
}

export default ProjectContentPage