const $ = window.$;
export function submitEmail (name, email) {
  return new Promise(function(resolve, reject){
    $.ajax({
      type: 'POST',
      url: '/api/email',
      data: { name: name, email: email }
    })
      .done((data) => {
        resolve(data)
      })
      .fail((error) => {
        reject(error)
      });
  })
}

export function submitMessage (name, email, message) {
  return new Promise(function(resolve, reject){
    $.ajax({
      type: 'POST',
      url: '/api/message',
      data: { name: name, email: email, message: message }
    })
      .done((data) => {
        resolve(data)
      })
      .fail((error) => {
        reject(error)
      });
  })
}