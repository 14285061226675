import React from 'react'
import MainButton from 'components/Widgets/MainButton';
const $ = window.$;

export default class ProjectContentBoard extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			
		}
		
	}

	componentDidMount () {
		this.addEventListeners();
	}

	shouldComponentUpdate(nextProps, nextState) {
		return false;
	}

	addEventListeners () {
		//Ensure top of content board is placed -290px inward from the bottom of screen
		this.handleContentBoardFormat();
		$(window).on('resize', () =>  {
			this.handleContentBoardFormat();
		})
	}

	handleContentBoardFormat () {

		var contentBoard = $(".content-board")
		
		var contentBoardHeight = contentBoard.height();

		var windowWidth = $(window).width();
		var windowHeight = $(window).height();
		if (windowWidth < 415) {
			contentBoard.css({'top': windowHeight - 85});
		} else {
			contentBoard.css({'bottom': -contentBoardHeight + 220});
		}
	}

	handleButtonScroll () {
		var contentPage = $(".page-contentpage")
		var projectBrief = $(".project-brief")
		
		var windowWidth = $(window).width();

		if (windowWidth < 415) {
			contentPage.stop(true, false).animate({scrollTop: projectBrief.offset().top - 360} , 1000);
		} else {
			contentPage.stop(true, false).animate({scrollTop: projectBrief.offset().top - 300} , 1000);
		}
	}
		

	buildBlogContentComponent () {
		return (
			<h1>Hello</h1>
		)
	}

	builtProjectContentComponent () {

		const { headerColor, positionColor, titleColor, typeColor, briefColor, storyColor, skillColor, externalsColor, viewApplicationColor } = this.props.data.ContentPageData.board.styles;
		var headerStyle = {
			'color': headerColor
		}
		var positionStyle = {
			'color': positionColor
		}
		var viewApplicationStyle = {
			'color': viewApplicationColor
		}
		var titleStyle = {
			'color': titleColor
		}
		var typeStyle = {
			'color': typeColor
		}
		var briefStyle = {
			'color': briefColor
		}
		var storyStyle = {
			'color': storyColor
		}
		var skillStyle = {
			'color': skillColor
		}
		var externalsStyle = {
			'color': externalsColor,
			'borderColor': externalsColor
		}

				
		const { Externals, WebsiteData } = this.props.data;
		
		var externalContentButtons;
		if (Externals) {
			externalContentButtons = Object.keys(Externals).map((title, index) => {
										return (<a href={Externals[title]} style={externalsStyle} target="_blank">{title}</a>)
									})
		}


		return (
			<div className='project-content'>
				<div className='project-position'>
					<span style={headerStyle}>Position</span>
					<span style={positionStyle}>{this.props.data.ContentPageData.board.position}</span>
					{WebsiteData && WebsiteData.link && WebsiteData.link !== '' ? (<a href={WebsiteData.link} style={viewApplicationStyle} target="_blank">view application</a>) : (<div></div>)}
					
				</div>
				<span className='board-header' style={headerStyle}>
					Case
					<span style={{'backgroundColor': headerStyle.color}}></span>
				</span>
				<MainButton arrowColor={this.props.data.ContentPageData.board.styles.buttonArrowColor} backgroundColor={this.props.data.ContentPageData.board.styles.buttonColor} clickHandler={() => this.handleButtonScroll()} type={'expand'} position={'topRight'} direction={'pointDown'}/>
				<div className='project-title'>
					<span style={titleStyle}>{this.props.data.ContentPageData.board.title}</span>
					<span style={typeStyle}>{this.props.data.ContentPageData.board.type}</span>
				</div>
				<div className='project-lower'>
					<div className='project-brief' style={briefStyle}>
					{this.props.data.ContentPageData.board.brief}
					</div>
					<div className='project-description'>
						<div className='project-story'>
							<span style={headerStyle}>The story</span>
							<div style={storyStyle}>{
								this.props.data.ContentPageData.board.story.split("\n").map((line) => {
									if (line.length) return (<div>{line}<br/></div>);
								})
							}</div>

						</div>
						<div className='project-skills'>
							<span style={headerStyle}>Deliverables</span>
							{this.props.data.ContentPageData.board.deliverables.map(function(deliverable, i){
						        return <li key={i} style={skillStyle}><span>{deliverable}</span></li>;
						    })}
						</div>
					</div>
					<div className='project-externals'>
						{externalContentButtons}
					</div>
				</div>
				
				
						
				
				
			</div>
		)
	}

	render() {
		var contentBoardComponent;
		var contentBoardStyle = {
			backgroundColor: this.props.data.ContentPageData.board.styles.backgroundColor
		}
		if (this.props.data.DataType === 'project') {
			contentBoardComponent = this.builtProjectContentComponent();
		} else if (this.props.data.DataType === 'blog') {
			contentBoardComponent = this.buildBlogContentComponent();
		} 
		return (
			<div className='content-board' style={contentBoardStyle} data-scroll-speed="2">
				{contentBoardComponent}
			</div>
		);
	}
}