import React from 'react';
import MainButton from 'components/Widgets/MainButton';
import _ from 'lodash';
import {deviceIsMobile} from 'lib/Helpers/JqueryHelpers';
import {retrieveGooglePhotosAlbumContents} from 'lib/Helpers/googlePhotos';
import {Animator} from './Animator.js';
import VideoWidget from 'components/Widgets/VideoWidget';
import Shuffle from 'shufflejs';
const $ = window.$;
const diamondIconSrc = '/media/Icons/diamond.png';


export default class BlogMainContent extends React.Component {

    constructor(props){
		super(props)
		this.state = {
            descriptionOverlayActive : false,
            videoIsPlaying: false,
            darkOverlayEnabled: false,
            storyHolderOpen: false,
            googlePhotosMediaItems: [],
            blogContentIsLoading: false
		}
		
    }

    /******Life Cycle Functions********/
    componentDidMount () {

        this.setState({blogContentIsLoading:true})
        
        setTimeout(() => {
            /*
            Animate in dark overlay after blog-post page transition completes - needed to do this because the dark-overlay expands outside of the screens bounds and thee user will see the dark overlay while transitioning from the previous page into the blog post page
            */
            $('.dark-overlay').animate({ 'opacity': 1.0 });
            

        },500)

        setTimeout(() => {
            /* setting up shuffle widget */ 
            var shuffleContainer = $('.shuffle-container');
            this.shuffle = new Shuffle(shuffleContainer, {
              itemSelector: '.js-item',
              sizer: '.my-sizer-element',
            });
        },2000)
        


        var mediaItems = $('.media-item');

        $('.blog-section-main-content').on('scroll', () => {

            var $mediaContainer = $('.media-container');
            var mediaContainerIsComingIntoView = $mediaContainer.hasClass('off-screen') && $mediaContainer.offset().top < $(window).height();
            var mediaContainerIsLeavingView = $mediaContainer.hasClass('on-screen') && $mediaContainer.offset().top >= $(window).height() - 5;
            
            if (mediaContainerIsComingIntoView) {
                $mediaContainer.removeClass('off-screen').addClass('on-screen')
                this.toggleStoryHolder(false);
                this.toggleBackgroundVideo(false);
                this.animateFooter(false);
                this.toggleDarkOverlay(true)                
            } else if (mediaContainerIsLeavingView) {
                $mediaContainer.removeClass('on-screen').addClass('off-screen')
                this.toggleDarkOverlay(false)
                this.animateFooter(true);
                this.toggleBackgroundVideo(true);               
            }
        });

        if (this.props.content.googlePhotoAlbumId) {
            retrieveGooglePhotosAlbumContents(this.props.content.googlePhotoAlbumId).then((retrievedGooglePhotosMediaItems) => {
                this.setState({googlePhotosMediaItems:retrievedGooglePhotosMediaItems, blogContentIsLoading: false})
            })
        }  
        
    }
    

    componentDidUpdate () {
        // Notify shuffle to dump the elements it's currently holding and consider
        // all elements matching the `itemSelector` as new.
        if (this.shuffle) this.shuffle.resetItems(); 
    }
    /****************************/
    
    
    /******Toggle Functions********/
    toggleDarkOverlay (toggleDarkOverlayOn) {

        const addDarkOverlay = () =>  {
            this.setState({darkOverlayEnabled: true})
            $('.dark-overlay').css({ 'background-color': 'rgba(0,0,0,0.7)' });
        }
    
        const removeDarkOverlay = () => {
            this.setState({darkOverlayEnabled: false})
            $('.dark-overlay').css({ 'background-color': 'rgba(0,0,0,0.175)' });
        }

        if (toggleDarkOverlayOn) {
            addDarkOverlay();
        } else  {
            removeDarkOverlay();
        }
 
    }
    animateFooter (animateFooterIn) {
                
        if (animateFooterIn) {
            Animator.slideInformationFooterInFromLeft.performAnimation();
        } else {
            Animator.slideInformationFooterOutToLeft.performAnimation();
        }
        
    }
    toggleBackgroundVideo (toggleBackgroundVideoIn) {
        toggleBackgroundVideoIn ? $('.bg-video').fadeIn() : $('.bg-video').fadeOut()
    }
    handleBackgroundVideoClick (videoPlayer) {
        videoPlayer.togglePlay();	
    }

    handleMediaItemVideoClick (videoPlayer) {
        videoPlayer.togglePlay();
    }

    toggleStoryHolder (toggleStoryHolderIntoView) {
       
        var $mediaContainer = $('.media-container');

        const addStoryHolder = () => {
            this.setState({storyHolderOpen: true});
            this.toggleDarkOverlay(true)
            $('.story-holder').fadeIn()
            
        }

        const removeStoryHolder = () => {
            this.setState({storyHolderOpen: false});
            this.toggleDarkOverlay(false)
            $('.story-holder').fadeOut()
        }
        
        if (toggleStoryHolderIntoView) {
            $('.blog-section-main-content').css('overflowY','hidden');
            addStoryHolder();
        } else {
            removeStoryHolder();
            $('.blog-section-main-content').css('overflowY','scroll');
        }
    }

    /****************************/
    


    

    /******BUILD FUNCTIONS********/
    buildShuffleWidget () {
        return (
            <div className="shuffle-container row" style={{'zIndex':1, 'width':'100%'}}>
                {this.state.googlePhotosMediaItems.map((mediaData, index) => {
                    var mediaContent;

                    // if (index < 13 || index > 20) return;

                    if (mediaData.mediaMetadata.hasOwnProperty('photo')) {
                        mediaContent = (<img src={mediaData.baseUrl}/>)
                    } else if (mediaData.mediaMetadata.hasOwnProperty('video')) {
                        var videoPlayerData = {'src': mediaData.baseUrl + '=dv'}
                        const videoPlayerOptions = {
                            clickToPlay: false,
                            controls: ['play-large']
                        }

                        const toggleLoader = (videoPlayer, showLoader) => {
                            showLoader ? $('#bg-video-loader').css('opacity',1.0) : $('#bg-video-loader').css('opacity',0.0)
                        }
                        mediaContent = (<VideoWidget 
                                            elementId={'player_'+index} 
                                            videoPlayerData={videoPlayerData} 
                                            videoPlayerOptions={videoPlayerOptions} 
                                            onClick={(videoPlayer) => this.handleMediaItemVideoClick(videoPlayer)}
                                            onBuffering={(videoPlayer) => toggleLoader(videoPlayer, true)}
                                            onPlaying={(videoPlayer) => toggleLoader(videoPlayer, false)}
                                        />)
                    }
                                       
                    
                    const aspectRatio = ((100/mediaData.mediaMetadata.width) * mediaData.mediaMetadata.height) + '%';

                    return (
                        <figure className={"js-item img-item "} key={index} style={{'padding':'10px'}}>
                          <div className={"aspect"} style={{'paddingBottom':aspectRatio}}>
                            <div className="aspect__inner">
                                {mediaContent}
                            </div>
                          </div>
                        </figure>
                    )
                })}
                <div className="column my-sizer-element"></div>
            </div>
        )
    }

    buildVideoBackground () {
        const videoPlayerOptions = {
            clickToPlay: false,
            controls: ['play-large', 'play', 'progress', 'fullscreen'],
            fullscreen: { enabled: true, fallback: true, iosNative: true }
        }
        var videoPlayerData = {
            'src': this.props.content.backgroundVideo.source,
            'origin': this.props.content.backgroundVideo.origin
        }

        const toggleLoader = (videoPlayer, showLoader) => {
            showLoader ? $('#bg-video-loader').css('opacity',1.0) : $('#bg-video-loader').css('opacity',0.0)
        }

        return (
            <div className='bg-video'>
                <img src="/media/loaders/tail-spin.svg" id="bg-video-loader" style={{'opacity':'0.0'}}/>
                <VideoWidget 
                    elementId={'bg-video'} 
                    videoPlayerData={videoPlayerData} 
                    videoPlayerOptions={videoPlayerOptions} 
                    transparentBackground={true} 
                    onClick={(videoPlayer) => this.handleBackgroundVideoClick(videoPlayer)} 
                    onBuffering={(videoPlayer) => toggleLoader(videoPlayer, true)}
                    onPlaying={(videoPlayer) => toggleLoader(videoPlayer, false)}
                />
            </div>
        )
    }
    buildFooter () {
        return (
            <div className='information-footer'>
                <h1 className='location-degrees'>{this.props.content.header}</h1>
                <h1 className='location-name'>{this.props.content.subheader}</h1>
                {this.props.content.story ? (<p1 style={{'cursor':'pointer'}}  onClick={() => this.toggleStoryHolder(!this.state.storyHolderOpen)} className='read-more'>{this.state.storyHolderOpen ? 'Read Less' : 'Read More' }</p1>) : (<p1></p1>)}
            </div>
        )
    }
    buildStoryHolder () {
        var lines = this.props.content.story.split("\n").map((line) => {
            if (line.length) return (<span>{line}<br/></span>);
        })
        return (
            <div className='story-holder' style={{'display': this.state.storyHolderOpen ? 'flex' : 'none', 'zIndex':1}}>
                <h1>The Story</h1>
                <p1>{lines}</p1>
            </div>
        )
    }
    buildOverview () {
        const overviewStyle = {
            'height':$(window).height()
        }
       return (
        <div className='overview scrollify-section' style={overviewStyle}>
            { this.props.content.story ? this.buildStoryHolder() : ''}
            {this.buildFooter()}
        </div>
       ) 
    }
    buildDarkOverlay () {
        return (
            <div className='dark-overlay'></div>
        )
    }
    /****************************/
    
    
    render() {   
            var blogSectionMainContentStyle = {
                'backgroundImage':'url(' + this.props.content.backgroundImageUrl + ')',
                'overflowY': this.state.blogContentIsLoading ? 'hidden' : 'scroll'
            }
            return (
                <div className='blog-section-main-content' id={'blog-section-' + this.props.index} style={blogSectionMainContentStyle}>
                        <img className='loader' src="/media/loaders/tail-spin.svg" style={{'visibility':this.state.blogContentIsLoading ? 'visible' : 'hidden'}}/>
                        {this.props.content.backgroundVideo ? this.buildVideoBackground() : (<div className='no-bg-video'></div>)}
                        {this.buildDarkOverlay()}
                        {this.buildOverview()}
                        <div className='media-container off-screen'>
                            {this.buildShuffleWidget ()}
                        </div>
                </div> 
            )
	}
}