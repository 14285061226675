import {DefaultDataItem} from 'data/DefaultDataItem';
import {ProjectDataSet as ProjectData} from 'data/ProjectDataSet';
import {BlogDataSet as BlogData} from 'data/BlogDataSet';

var _ = require('lodash');


function resolveProjectsData () {
	return ProjectData.map(function(ProjectData){
		return _.merge({}, DefaultDataItem, ProjectData);
	})
}

export const ProjectDataSet = resolveProjectsData();
export const BlogDataSet = BlogData

